import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    /**
     * The `DateTime` scalar type represents a DateTime
     * value as specified by
     * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
     */
    DateTime: any
    /** The `Decimal` scalar type represents a python Decimal. */
    Decimal: any
    /**
     * Allows use of a JSON String for input / output from the GraphQL schema.
     *
     * Use of this type is *not recommended* as you lose the benefits of having a defined, static
     * schema (one of the key benefits of GraphQL).
     */
    JSONString: any
    /**
     * Leverages the internal Python implementation of UUID (uuid.UUID) to provide native UUID objects
     * in fields, resolvers and input.
     */
    UUID: string
}

export type AdminBaseTest = {
    __typename?: "AdminBaseTest"
    assignedTo?: Maybe<AdminBaseUser>
    availableTime: Scalars["Int"]
    /** Average score of solutions */
    averageSolutionScore?: Maybe<Scalars["Decimal"]>
    /** Average time in seconds spent on solutions */
    averageSolutionTime?: Maybe<Scalars["Int"]>
    competencies?: Maybe<Array<Maybe<Competency>>>
    competencyNames: Array<Maybe<Scalars["String"]>>
    /** Number of submitted solutions to the test */
    completedBy: Scalars["Int"]
    createdAt: Scalars["DateTime"]
    description?: Maybe<Scalars["String"]>
    disabledAt?: Maybe<Scalars["DateTime"]>
    experimental?: Maybe<Scalars["Boolean"]>
    locale: Locale
    mostRecentActivityAt?: Maybe<Scalars["DateTime"]>
    mostRecentActivitySecondsAgo?: Maybe<Scalars["Int"]>
    numberOfQuestions: Scalars["Int"]
    organizationName: Scalars["String"]
    questionsNo: Scalars["Int"]
    requiresExternalTool: Scalars["Boolean"]
    roleName: Scalars["String"]
    seniority: Seniority
    solutionsDistribution: Array<Maybe<Bin>>
    testState: TestState
    testType: TestType
    title: Scalars["String"]
    totalTime: Scalars["Int"]
    typeformTestId?: Maybe<Scalars["String"]>
    updatedAt?: Maybe<Scalars["DateTime"]>
    uuid: Scalars["UUID"]
}

export type AdminBaseUser = {
    __typename?: "AdminBaseUser"
    accountId: Scalars["Int"]
    accountLogUrl: Scalars["String"]
    blocked: Scalars["Boolean"]
    email: Scalars["String"]
    firstName: Scalars["String"]
    isAdmin: Scalars["Boolean"]
    lastName: Scalars["String"]
    locale: Locale
    logUrl: Scalars["String"]
    organizationName?: Maybe<Scalars["String"]>
    role?: Maybe<RecruiterRole>
    uuid: Scalars["UUID"]
}

export type AdminQuestionConnection = {
    __typename?: "AdminQuestionConnection"
    /** Contains the nodes in this connection. */
    edges: Array<Maybe<AdminQuestionEdge>>
    /** Pagination data for this connection. */
    pageInfo: PageInfo
    /** Total count of available results */
    totalCount: Scalars["Int"]
}

/** A Relay edge containing a `AdminQuestion` and its cursor. */
export type AdminQuestionEdge = {
    __typename?: "AdminQuestionEdge"
    /** A cursor for use in pagination */
    cursor: Scalars["String"]
    /** The item at the end of the edge */
    node?: Maybe<Question>
}

export type AdminSolutionConnection = {
    __typename?: "AdminSolutionConnection"
    /** Contains the nodes in this connection. */
    edges: Array<Maybe<AdminSolutionEdge>>
    /** Pagination data for this connection. */
    pageInfo: PageInfo
    /** Total count of available results */
    totalCount: Scalars["Int"]
}

/** A Relay edge containing a `AdminSolution` and its cursor. */
export type AdminSolutionEdge = {
    __typename?: "AdminSolutionEdge"
    /** A cursor for use in pagination */
    cursor: Scalars["String"]
    /** The item at the end of the edge */
    node?: Maybe<Solution>
}

export type AdminTest = {
    __typename?: "AdminTest"
    assignedTo?: Maybe<AdminBaseUser>
    availableTime: Scalars["Int"]
    /** Average score of solutions */
    averageSolutionScore?: Maybe<Scalars["Decimal"]>
    /** Average time in seconds spent on solutions */
    averageSolutionTime?: Maybe<Scalars["Int"]>
    averageSummary?: Maybe<Array<Maybe<SummaryEntry>>>
    competencies?: Maybe<Array<Maybe<Competency>>>
    competencyNames: Array<Maybe<Scalars["String"]>>
    /** Number of submitted solutions to the test */
    completedBy: Scalars["Int"]
    createdAt: Scalars["DateTime"]
    description?: Maybe<Scalars["String"]>
    disabledAt?: Maybe<Scalars["DateTime"]>
    experimental?: Maybe<Scalars["Boolean"]>
    isEditable: Scalars["Boolean"]
    locale: Locale
    mostRecentActivityAt?: Maybe<Scalars["DateTime"]>
    mostRecentActivitySecondsAgo?: Maybe<Scalars["Int"]>
    numberOfQuestions: Scalars["Int"]
    organizationName: Scalars["String"]
    questions?: Maybe<Array<Maybe<Question>>>
    questionsNo: Scalars["Int"]
    requiresExternalTool: Scalars["Boolean"]
    roleName: Scalars["String"]
    seniority: Seniority
    solutionUuids?: Maybe<Array<Maybe<Scalars["UUID"]>>>
    solutionsDistribution: Array<Maybe<Bin>>
    testState: TestState
    testType: TestType
    title: Scalars["String"]
    totalTime: Scalars["Int"]
    typeformTestId?: Maybe<Scalars["String"]>
    updatedAt?: Maybe<Scalars["DateTime"]>
    uuid: Scalars["UUID"]
}

export type AdminTestConnection = {
    __typename?: "AdminTestConnection"
    /** Contains the nodes in this connection. */
    edges: Array<Maybe<AdminTestEdge>>
    /** Pagination data for this connection. */
    pageInfo: PageInfo
    /** Total count of available results */
    totalCount: Scalars["Int"]
}

/** A Relay edge containing a `AdminTest` and its cursor. */
export type AdminTestEdge = {
    __typename?: "AdminTestEdge"
    /** A cursor for use in pagination */
    cursor: Scalars["String"]
    /** The item at the end of the edge */
    node?: Maybe<AdminBaseTest>
}

export type AdminUserConnection = {
    __typename?: "AdminUserConnection"
    /** Contains the nodes in this connection. */
    edges: Array<Maybe<AdminUserEdge>>
    /** Pagination data for this connection. */
    pageInfo: PageInfo
    /** Total count of available results */
    totalCount: Scalars["Int"]
}

/** A Relay edge containing a `AdminUser` and its cursor. */
export type AdminUserEdge = {
    __typename?: "AdminUserEdge"
    /** A cursor for use in pagination */
    cursor: Scalars["String"]
    /** The item at the end of the edge */
    node?: Maybe<BaseUser>
}

export type Answer = {
    __typename?: "Answer"
    content: Scalars["String"]
    correct: Scalars["Boolean"]
    uuid: Scalars["UUID"]
}

export type AnswerGiven = {
    __typename?: "AnswerGiven"
    answer?: Maybe<Answer>
    content?: Maybe<Scalars["String"]>
    correct?: Maybe<Scalars["Boolean"]>
    userSelected: Scalars["Boolean"]
    uuid: Scalars["UUID"]
}

export type AnswerGivenInput = {
    answerId?: Maybe<Scalars["UUID"]>
    text?: Maybe<Scalars["String"]>
}

export type Assessment = {
    __typename?: "Assessment"
    candidate: SolutionCandidate
    createdAt: Scalars["DateTime"]
    responses?: Maybe<Array<Maybe<Response>>>
    score: Scalars["Decimal"]
    seenAt?: Maybe<Scalars["DateTime"]>
    solutionState: SolutionState
    starred: Scalars["Boolean"]
    submittedAt: Scalars["DateTime"]
    summary?: Maybe<Array<Maybe<SummaryEntry>>>
    test: BaseTest
    testRoleName: Scalars["String"]
    timeSpent: Scalars["Int"]
    updatedAt: Scalars["DateTime"]
    uuid: Scalars["UUID"]
}

export type Auth = {
    __typename?: "Auth"
    authorizationToken: Scalars["String"]
}

export type BaseSolution = {
    __typename?: "BaseSolution"
    candidate: SolutionCandidate
    createdAt: Scalars["DateTime"]
    score: Scalars["Decimal"]
    seenAt?: Maybe<Scalars["DateTime"]>
    solutionState: SolutionState
    starred: Scalars["Boolean"]
    submittedAt: Scalars["DateTime"]
    summary?: Maybe<Array<Maybe<SummaryEntry>>>
    testRoleName: Scalars["String"]
    timeSpent: Scalars["Int"]
    updatedAt: Scalars["DateTime"]
    uuid: Scalars["UUID"]
}

export type BaseTest = {
    __typename?: "BaseTest"
    /** Owner of the test (HR user / organization) */
    assignedTo?: Maybe<BaseUser>
    availableTime: Scalars["Int"]
    /** Average score of solutions */
    averageSolutionScore?: Maybe<Scalars["Decimal"]>
    /** Average time in seconds spent on solutions */
    averageSolutionTime?: Maybe<Scalars["Int"]>
    competencies?: Maybe<Array<Maybe<Competency>>>
    competencyNames: Array<Maybe<Scalars["String"]>>
    /** Number of submitted solutions to the test */
    completedBy: Scalars["Int"]
    createdAt: Scalars["DateTime"]
    description?: Maybe<Scalars["String"]>
    disabledAt?: Maybe<Scalars["DateTime"]>
    experimental?: Maybe<Scalars["Boolean"]>
    locale: Locale
    mostRecentActivityAt?: Maybe<Scalars["DateTime"]>
    mostRecentActivitySecondsAgo?: Maybe<Scalars["Int"]>
    numberOfQuestions: Scalars["Int"]
    organizationName: Scalars["String"]
    requiresExternalTool: Scalars["Boolean"]
    roleName: Scalars["String"]
    seniority: Seniority
    solutionsDistribution: Array<Maybe<Bin>>
    testState: TestState
    testType: TestType
    title: Scalars["String"]
    typeformTestId?: Maybe<Scalars["String"]>
    updatedAt?: Maybe<Scalars["DateTime"]>
    uuid: Scalars["UUID"]
}

export type BaseTestQuestion = {
    text: Scalars["String"]
    uuid: Scalars["UUID"]
}

export type BaseTestQuestionWithAnswers = {
    possibleAnswers: Array<Maybe<QuestionAnswer>>
}

export type BaseUser = {
    __typename?: "BaseUser"
    accountId: Scalars["Int"]
    accountLogUrl: Scalars["String"]
    email: Scalars["String"]
    firstName: Scalars["String"]
    isAdmin: Scalars["Boolean"]
    lastName: Scalars["String"]
    locale: Locale
    logUrl: Scalars["String"]
    organizationName?: Maybe<Scalars["String"]>
    role?: Maybe<RecruiterRole>
    uuid: Scalars["UUID"]
}

export type Bin = {
    __typename?: "Bin"
    label: Scalars["String"]
    value: Scalars["Int"]
}

export type Candidate = {
    __typename?: "Candidate"
    /** @deprecated Renamed: assessment -> solution. */
    assessments?: Maybe<Array<Maybe<Assessment>>>
    createdAt: Scalars["DateTime"]
    disabled?: Maybe<Scalars["Boolean"]>
    email: Scalars["String"]
    firstName: Scalars["String"]
    lastName: Scalars["String"]
    phoneNumber?: Maybe<Scalars["String"]>
    solutions?: Maybe<Array<Maybe<BaseSolution>>>
    updatedAt?: Maybe<Scalars["DateTime"]>
    uuid: Scalars["UUID"]
}

export type CandidateAttemptSolution = {
    __typename?: "CandidateAttemptSolution"
    /** Id of the typeform form for the test (typeform id). */
    typeformTestId: Scalars["String"]
}

export type CandidateGiveFeedback = {
    __typename?: "CandidateGiveFeedback"
    result: Scalars["String"]
}

export type CandidateMutation = {
    __typename?: "CandidateMutation"
    seeQuestion?: Maybe<SeeQuestion>
    startSolution?: Maybe<StartSolution>
    submitResponse?: Maybe<SubmitResponse>
    submitSolution?: Maybe<SubmitSolution>
}

export type CandidateMutationSeeQuestionArgs = {
    input: SeeQuestionInput
}

export type CandidateMutationStartSolutionArgs = {
    input: StartSolutionInput
}

export type CandidateMutationSubmitResponseArgs = {
    input: SubmitResponseInput
}

export type CandidateMutationSubmitSolutionArgs = {
    input: SubmitSolutionInput
}

export type CandidatePreview = {
    __typename?: "CandidatePreview"
    email: Scalars["String"]
    firstName: Scalars["String"]
    lastName: Scalars["String"]
    phoneNumber?: Maybe<Scalars["String"]>
    uuid: Scalars["UUID"]
}

export type CandidateSolutionPreview = {
    __typename?: "CandidateSolutionPreview"
    candidate: CandidatePreview
    score?: Maybe<Scalars["Decimal"]>
    solutionState: SolutionState
    summary?: Maybe<Array<Maybe<SummaryEntry>>>
    test: TestPreview
    timeSpent?: Maybe<Scalars["Int"]>
}

/** An enumeration. */
export enum CandidateStatus {
    Good = "GOOD",
    Maybe = "MAYBE",
    New = "NEW",
    Rejected = "REJECTED",
    Seen = "SEEN",
}

/** An enumeration. */
export enum CandidateStatusInput {
    Good = "GOOD",
    Maybe = "MAYBE",
    Rejected = "REJECTED",
}

export type Competency = {
    __typename?: "Competency"
    description: Scalars["String"]
    domain: Scalars["String"]
    icon: Icon
    name: Scalars["String"]
    uuid: Scalars["UUID"]
}

export type CompetencyConnection = {
    __typename?: "CompetencyConnection"
    /** Contains the nodes in this connection. */
    edges: Array<Maybe<CompetencyEdge>>
    /** Pagination data for this connection. */
    pageInfo: PageInfo
    /** Total count of available results */
    totalCount: Scalars["Int"]
}

/** A Relay edge containing a `Competency` and its cursor. */
export type CompetencyEdge = {
    __typename?: "CompetencyEdge"
    /** A cursor for use in pagination */
    cursor: Scalars["String"]
    /** The item at the end of the edge */
    node?: Maybe<Competency>
}

export type CreateOpenQuestion = {
    __typename?: "CreateOpenQuestion"
    content: Scalars["String"]
    uuid: Scalars["UUID"]
}

export type CreateTest = {
    __typename?: "CreateTest"
    result?: Maybe<AdminTest>
}

export type CreateUser = {
    __typename?: "CreateUser"
    resetToken: Scalars["String"]
    user: AdminBaseUser
}

export type DeleteOpenQuestion = {
    __typename?: "DeleteOpenQuestion"
    result: Scalars["String"]
}

export type DeleteTest = {
    __typename?: "DeleteTest"
    result?: Maybe<AdminTest>
}

export type DeleteUser = {
    __typename?: "DeleteUser"
    result?: Maybe<AdminBaseUser>
}

/** An enumeration. */
export enum Difficulty {
    Easy = "EASY",
    Hard = "HARD",
    Medium = "MEDIUM",
}

export type DisableTest = {
    __typename?: "DisableTest"
    result?: Maybe<AdminTest>
}

export type DuplicateTest = {
    __typename?: "DuplicateTest"
    result?: Maybe<AdminTest>
}

export type EnableTest = {
    __typename?: "EnableTest"
    result?: Maybe<AdminTest>
}

export type Error = {
    __typename?: "Error"
    messages?: Maybe<Array<Maybe<Scalars["String"]>>>
    name: Scalars["String"]
}

export type ExistingOpenQuestion = {
    id: Scalars["UUID"]
}

export type Get2FaToken = {
    __typename?: "Get2FAToken"
    intermediateToken: Scalars["String"]
}

export type HeroTracking = {
    __typename?: "HeroTracking"
    result: Scalars["String"]
}

/** An enumeration. */
export enum Icon {
    AttentionToDetails = "ATTENTION_TO_DETAILS",
    CognitiveSkills = "COGNITIVE_SKILLS",
    GoogleAds = "GOOGLE_ADS",
    GoogleAnalytics = "GOOGLE_ANALYTICS",
    MarketingB2B = "MARKETING_B2B",
}

export type ImpersonateUser = {
    __typename?: "ImpersonateUser"
    /** Authorization token of the user with impersonation metadata */
    authorizationToken: Scalars["String"]
}

export type Issue2FaToken = {
    __typename?: "Issue2FAToken"
    result: Scalars["String"]
}

/** An enumeration. */
export enum Locale {
    En = "EN",
    Pl = "PL",
}

export type MultipleChoiceQuestion = BaseTestQuestion &
    BaseTestQuestionWithAnswers & {
        __typename?: "MultipleChoiceQuestion"
        possibleAnswers: Array<Maybe<QuestionAnswer>>
        text: Scalars["String"]
        uuid: Scalars["UUID"]
    }

export type MutationsRoot = {
    __typename?: "MutationsRoot"
    /** Create an open question */
    adminCreateOpenQuestion?: Maybe<CreateOpenQuestion>
    /** Manually create a test draft as an admin */
    adminCreateTest?: Maybe<CreateTest>
    /** Create a HR user, acquiring a password reset token */
    adminCreateUser?: Maybe<CreateUser>
    /** Delete an open question */
    adminDeleteOpenQuestion?: Maybe<DeleteOpenQuestion>
    /** Delete a test as an admin */
    adminDeleteTest?: Maybe<DeleteTest>
    /** Delete a user as an admin */
    adminDeleteUser?: Maybe<DeleteUser>
    /** Disable an active test as an admin */
    adminDisableTest?: Maybe<DisableTest>
    /** Duplicate a test in any state to a new draft */
    adminDuplicateTest?: Maybe<DuplicateTest>
    /** Enable an inactive test as an admin */
    adminEnableTest?: Maybe<EnableTest>
    /** Acquire an authorization token to impersonate an existing user */
    adminImpersonateUser?: Maybe<ImpersonateUser>
    /** Publish a test draft as an admin */
    adminPublishTest?: Maybe<PublishTest>
    /** Toggle user block as an admin */
    adminToggleBlockUser?: Maybe<ToggleBlockUser>
    /** Acquire a password reset token for an existing user */
    adminTriggerPasswordReset?: Maybe<TriggerPasswordReset>
    /** Update an open question */
    adminUpdateOpenQuestion?: Maybe<UpdateOpenQuestion>
    /** Update a test draft as an admin */
    adminUpdateTest?: Maybe<UpdateTest>
    /** Update a HR user */
    adminUpdateUser?: Maybe<UpdateUser>
    /** Authenticate using an email and password */
    auth?: Maybe<Auth>
    candidate?: Maybe<CandidateMutation>
    /** Attempt solution as a candidate using a session token. */
    candidateAttemptSolution?: Maybe<CandidateAttemptSolution>
    candidateGet2faToken?: Maybe<Get2FaToken>
    /** Give feedback as a candidate using a session token. */
    candidateGiveFeedback?: Maybe<CandidateGiveFeedback>
    /** Issue 2FA token for phone number verification as a candidate. */
    candidateIssue2faToken?: Maybe<Issue2FaToken>
    /** Verify a 2FA token and receive an intermediate token as a candidate. */
    candidateVerify2faToken?: Maybe<Verify2FaToken>
    hero?: Maybe<HeroTracking>
    recruiter?: Maybe<RecruiterMutation>
    /** Recruiter archive a test */
    recruiterArchiveTest?: Maybe<RecruiterArchiveTest>
    /** Recruiter delete a test */
    recruiterDeleteTest?: Maybe<RecruiterDeleteTest>
    /** Recruiter see a solution */
    recruiterSeeSolution?: Maybe<RecruiterSeeSolution>
    /** Recruiter Sign Up */
    recruiterSignUp?: Maybe<SignUp>
    /** Recruiter star a solution */
    recruiterStarSolution?: Maybe<RecruiterStarSolution>
    /** Register as a candidate using an intermediate token from 2FA verification. */
    registerCandidate?: Maybe<RegisterCandidate>
    /** Reset password using a password reset token */
    resetPassword?: Maybe<ResetPassword>
    /** Send reset password */
    sendResetPassword?: Maybe<SendResetPassword>
    /** Mutation for testing purposes */
    test?: Maybe<TestMutation>
    /** Wizard create a test */
    wizardCreateTest?: Maybe<RecruiterWizardCreateTest>
}

export type MutationsRootAdminCreateOpenQuestionArgs = {
    content: Scalars["String"]
}

export type MutationsRootAdminCreateTestArgs = {
    availableTime: Scalars["Int"]
    description: Scalars["String"]
    experimental?: Maybe<Scalars["Boolean"]>
    questions: Array<Maybe<Scalars["UUID"]>>
    roleName: Scalars["String"]
    seniority: Seniority
    testType: TestType
    title: Scalars["String"]
    user?: Maybe<Scalars["UUID"]>
}

export type MutationsRootAdminCreateUserArgs = {
    email: Scalars["String"]
    firstName: Scalars["String"]
    lastName: Scalars["String"]
    locale: Locale
    organizationName?: Maybe<Scalars["String"]>
}

export type MutationsRootAdminDeleteOpenQuestionArgs = {
    id: Scalars["UUID"]
}

export type MutationsRootAdminDeleteTestArgs = {
    testUuid: Scalars["UUID"]
}

export type MutationsRootAdminDeleteUserArgs = {
    uuid: Scalars["UUID"]
}

export type MutationsRootAdminDisableTestArgs = {
    testUuid: Scalars["UUID"]
}

export type MutationsRootAdminDuplicateTestArgs = {
    newUser?: Maybe<Scalars["UUID"]>
    testUuid: Scalars["UUID"]
}

export type MutationsRootAdminEnableTestArgs = {
    testUuid: Scalars["UUID"]
}

export type MutationsRootAdminImpersonateUserArgs = {
    userId: Scalars["UUID"]
}

export type MutationsRootAdminPublishTestArgs = {
    testUuid: Scalars["UUID"]
}

export type MutationsRootAdminToggleBlockUserArgs = {
    uuid: Scalars["UUID"]
}

export type MutationsRootAdminTriggerPasswordResetArgs = {
    uuid: Scalars["UUID"]
}

export type MutationsRootAdminUpdateOpenQuestionArgs = {
    content: Scalars["String"]
    id: Scalars["UUID"]
}

export type MutationsRootAdminUpdateTestArgs = {
    availableTime?: Maybe<Scalars["Int"]>
    description?: Maybe<Scalars["String"]>
    experimental?: Maybe<Scalars["Boolean"]>
    questions?: Maybe<Array<Maybe<Scalars["UUID"]>>>
    roleName?: Maybe<Scalars["String"]>
    seniority?: Maybe<Seniority>
    testType?: Maybe<TestType>
    testUuid: Scalars["UUID"]
    title?: Maybe<Scalars["String"]>
}

export type MutationsRootAdminUpdateUserArgs = {
    email: Scalars["String"]
    firstName: Scalars["String"]
    id: Scalars["UUID"]
    lastName: Scalars["String"]
    locale: Locale
    organizationName?: Maybe<Scalars["String"]>
}

export type MutationsRootAuthArgs = {
    email: Scalars["String"]
    password: Scalars["String"]
}

export type MutationsRootCandidateAttemptSolutionArgs = {
    candidateSessionToken: Scalars["String"]
}

export type MutationsRootCandidateGet2faTokenArgs = {
    phoneNumber: Scalars["String"]
    testId: Scalars["UUID"]
}

export type MutationsRootCandidateGiveFeedbackArgs = {
    candidateSessionToken: Scalars["String"]
    feedbackScore?: Maybe<Scalars["Decimal"]>
    feedbackScoreVersion?: Maybe<Scalars["String"]>
    feedbackText?: Maybe<Scalars["String"]>
}

export type MutationsRootCandidateIssue2faTokenArgs = {
    phoneNumber: Scalars["String"]
    testId: Scalars["UUID"]
}

export type MutationsRootCandidateVerify2faTokenArgs = {
    phoneNumber: Scalars["String"]
    testId: Scalars["UUID"]
    verificationToken: Scalars["String"]
}

export type MutationsRootHeroArgs = {
    input: TrackInput
}

export type MutationsRootRecruiterArchiveTestArgs = {
    testId: Scalars["UUID"]
}

export type MutationsRootRecruiterDeleteTestArgs = {
    testId: Scalars["UUID"]
}

export type MutationsRootRecruiterSeeSolutionArgs = {
    seeSolution: SeeSolution
}

export type MutationsRootRecruiterSignUpArgs = {
    signUp: SignUpInput
}

export type MutationsRootRecruiterStarSolutionArgs = {
    starSolution: StarSolution
}

export type MutationsRootRegisterCandidateArgs = {
    email: Scalars["String"]
    firstName: Scalars["String"]
    intermediateToken: Scalars["String"]
    lastName: Scalars["String"]
}

export type MutationsRootResetPasswordArgs = {
    password: Scalars["String"]
    resetToken: Scalars["String"]
}

export type MutationsRootSendResetPasswordArgs = {
    resetPassword: SendResetPasswordInput
}

export type MutationsRootTestArgs = {
    name: Scalars["String"]
}

export type MutationsRootWizardCreateTestArgs = {
    input: WizardCreateTestInput
}

export type NewOpenQuestion = {
    text: Scalars["String"]
}

export type NumericQuestion = BaseTestQuestion & {
    __typename?: "NumericQuestion"
    text: Scalars["String"]
    uuid: Scalars["UUID"]
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
    __typename?: "PageInfo"
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<Scalars["String"]>
    /** When paginating forwards, are there more items? */
    hasNextPage: Scalars["Boolean"]
    /** When paginating backwards, are there more items? */
    hasPreviousPage: Scalars["Boolean"]
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<Scalars["String"]>
}

export type PositionInput = {
    description?: Maybe<Scalars["String"]>
    jobTitle: Scalars["String"]
    seniority?: Maybe<Seniority>
    testLevel: TestLevel
    testType: TestType
}

export type PublishTest = {
    __typename?: "PublishTest"
    result?: Maybe<AdminTest>
}

export type Question = {
    __typename?: "Question"
    answers?: Maybe<Array<Maybe<Answer>>>
    appendix?: Maybe<Scalars["String"]>
    availableTime: Scalars["Int"]
    competency?: Maybe<Competency>
    content: Scalars["String"]
    custom: Scalars["Boolean"]
    difficulty: Difficulty
    questionStatus: QuestionStatus
    questionType: QuestionType
    requiresExternalTool?: Maybe<Scalars["String"]>
    uuid: Scalars["UUID"]
}

export type QuestionAnswer = {
    __typename?: "QuestionAnswer"
    text: Scalars["String"]
    uuid: Scalars["UUID"]
}

/** An enumeration. */
export enum QuestionStatus {
    Deprecated = "DEPRECATED",
    Ready = "READY",
    Suggested = "SUGGESTED",
    Validation = "VALIDATION",
}

/** An enumeration. */
export enum QuestionType {
    ClosedNumber = "CLOSED_NUMBER",
    ClosedText = "CLOSED_TEXT",
    FileUpload = "FILE_UPLOAD",
    MultiChoice = "MULTI_CHOICE",
    OpenText = "OPEN_TEXT",
    SingleChoice = "SINGLE_CHOICE",
}

export type RecruiterArchiveTest = {
    __typename?: "RecruiterArchiveTest"
    result: Scalars["String"]
}

export type RecruiterDeleteTest = {
    __typename?: "RecruiterDeleteTest"
    result: Scalars["String"]
}

export type RecruiterMutation = {
    __typename?: "RecruiterMutation"
    /** Recruiter Sign Up */
    signUp?: Maybe<SignUp>
    updateCandidatesStatus?: Maybe<UpdateCandidatesStatus>
}

export type RecruiterMutationSignUpArgs = {
    signUp: SignUpInput
}

export type RecruiterMutationUpdateCandidatesStatusArgs = {
    input: UpdateCandidatesStatusInput
}

/** An enumeration. */
export enum RecruiterRole {
    Cxo = "CXO",
    HiringManager = "HIRING_MANAGER",
    HrAgency = "HR_AGENCY",
    InHouseHr = "IN_HOUSE_HR",
    Other = "OTHER",
}

export type RecruiterSeeSolution = {
    __typename?: "RecruiterSeeSolution"
    result: Scalars["String"]
}

export type RecruiterSignUpPayload = {
    __typename?: "RecruiterSignUpPayload"
    errors: Array<Maybe<Error>>
    status: Scalars["String"]
    user?: Maybe<SignUpUser>
}

export type RecruiterStarSolution = {
    __typename?: "RecruiterStarSolution"
    result: Scalars["String"]
}

export type RecruiterWizardCreateTest = {
    __typename?: "RecruiterWizardCreateTest"
    test?: Maybe<WizardTest>
}

export type RegisterCandidate = {
    __typename?: "RegisterCandidate"
    /** Candidate's session token. */
    candidateSessionToken: Scalars["String"]
}

export type ResetPassword = {
    __typename?: "ResetPassword"
    authorizationToken: Scalars["String"]
}

export type Response = {
    __typename?: "Response"
    answersGiven?: Maybe<Array<Maybe<AnswerGiven>>>
    index?: Maybe<Scalars["Int"]>
    outdated: Scalars["Boolean"]
    question?: Maybe<Question>
    score?: Maybe<Scalars["Decimal"]>
    uuid: Scalars["UUID"]
}

export type RootQuery = {
    __typename?: "RootQuery"
    adminQuestion?: Maybe<Question>
    adminQuestionConnection?: Maybe<AdminQuestionConnection>
    adminSolutionConnection?: Maybe<AdminSolutionConnection>
    adminTest?: Maybe<AdminTest>
    adminTestConnection?: Maybe<AdminTestConnection>
    adminUser?: Maybe<AdminBaseUser>
    adminUserConnection?: Maybe<AdminUserConnection>
    candidate?: Maybe<Candidate>
    /** Preview Candidate details with a candidate session token. */
    candidatePreview?: Maybe<CandidatePreview>
    /** Preview Solution details with a session token as a candidate. */
    candidateSolutionPreview?: Maybe<CandidateSolutionPreview>
    /** Preview Test details with just a test uuid as a candidate. */
    candidateTestPreview?: Maybe<TestPreviewResult>
    competencyConnection?: Maybe<CompetencyConnection>
    error?: Maybe<Scalars["String"]>
    hello?: Maybe<Scalars["String"]>
    me?: Maybe<BaseUser>
    solution?: Maybe<Solution>
    solutionConnection?: Maybe<SolutionConnection>
    suggestedCompetencies: SuggestedCompetencies
    suggestedOpenQuestions: SuggestedOpenQuestions
    supportedCompetencies?: Maybe<CompetencyConnection>
    test?: Maybe<Test>
    testConnection?: Maybe<TestConnection>
    testSolutionConnection?: Maybe<SolutionConnection>
}

export type RootQueryAdminQuestionArgs = {
    uuid: Scalars["UUID"]
}

export type RootQueryAdminQuestionConnectionArgs = {
    after?: Maybe<Scalars["String"]>
    before?: Maybe<Scalars["String"]>
    first?: Maybe<Scalars["Int"]>
    last?: Maybe<Scalars["Int"]>
    offset?: Maybe<Scalars["Int"]>
    search?: Maybe<Scalars["String"]>
}

export type RootQueryAdminSolutionConnectionArgs = {
    after?: Maybe<Scalars["String"]>
    before?: Maybe<Scalars["String"]>
    first?: Maybe<Scalars["Int"]>
    ids?: Maybe<Array<Maybe<Scalars["UUID"]>>>
    last?: Maybe<Scalars["Int"]>
    offset?: Maybe<Scalars["Int"]>
    search?: Maybe<Scalars["String"]>
}

export type RootQueryAdminTestArgs = {
    uuid: Scalars["UUID"]
}

export type RootQueryAdminTestConnectionArgs = {
    after?: Maybe<Scalars["String"]>
    before?: Maybe<Scalars["String"]>
    descending?: Maybe<Scalars["Boolean"]>
    first?: Maybe<Scalars["Int"]>
    last?: Maybe<Scalars["Int"]>
    offset?: Maybe<Scalars["Int"]>
    orderBy?: Maybe<Array<Maybe<Scalars["String"]>>>
    search?: Maybe<Scalars["String"]>
}

export type RootQueryAdminUserArgs = {
    uuid: Scalars["UUID"]
}

export type RootQueryAdminUserConnectionArgs = {
    after?: Maybe<Scalars["String"]>
    before?: Maybe<Scalars["String"]>
    first?: Maybe<Scalars["Int"]>
    last?: Maybe<Scalars["Int"]>
    offset?: Maybe<Scalars["Int"]>
    search?: Maybe<Scalars["String"]>
}

export type RootQueryCandidateArgs = {
    uuid: Scalars["UUID"]
}

export type RootQueryCandidatePreviewArgs = {
    sessionToken: Scalars["String"]
}

export type RootQueryCandidateSolutionPreviewArgs = {
    sessionToken: Scalars["String"]
}

export type RootQueryCandidateTestPreviewArgs = {
    uuid: Scalars["UUID"]
}

export type RootQueryCompetencyConnectionArgs = {
    after?: Maybe<Scalars["String"]>
    before?: Maybe<Scalars["String"]>
    first?: Maybe<Scalars["Int"]>
    last?: Maybe<Scalars["Int"]>
    offset?: Maybe<Scalars["Int"]>
}

export type RootQueryErrorArgs = {
    culprit?: Maybe<Scalars["String"]>
}

export type RootQuerySolutionArgs = {
    uuid: Scalars["UUID"]
}

export type RootQuerySolutionConnectionArgs = {
    after?: Maybe<Scalars["String"]>
    before?: Maybe<Scalars["String"]>
    descending?: Maybe<Scalars["Boolean"]>
    first?: Maybe<Scalars["Int"]>
    ids?: Maybe<Array<Maybe<Scalars["UUID"]>>>
    last?: Maybe<Scalars["Int"]>
    offset?: Maybe<Scalars["Int"]>
    orderBy?: Maybe<Array<Maybe<Scalars["String"]>>>
    orderScoreByCompetency?: Maybe<Scalars["UUID"]>
    search?: Maybe<Scalars["String"]>
}

export type RootQuerySuggestedCompetenciesArgs = {
    position?: Maybe<PositionInput>
}

export type RootQuerySuggestedOpenQuestionsArgs = {
    input?: Maybe<Array<Maybe<WizardCompetencyInput>>>
}

export type RootQuerySupportedCompetenciesArgs = {
    after?: Maybe<Scalars["String"]>
    before?: Maybe<Scalars["String"]>
    first?: Maybe<Scalars["Int"]>
    last?: Maybe<Scalars["Int"]>
    offset?: Maybe<Scalars["Int"]>
    position: PositionInput
}

export type RootQueryTestArgs = {
    uuid: Scalars["UUID"]
}

export type RootQueryTestConnectionArgs = {
    after?: Maybe<Scalars["String"]>
    before?: Maybe<Scalars["String"]>
    first?: Maybe<Scalars["Int"]>
    last?: Maybe<Scalars["Int"]>
    offset?: Maybe<Scalars["Int"]>
    search?: Maybe<Scalars["String"]>
}

export type RootQueryTestSolutionConnectionArgs = {
    after?: Maybe<Scalars["String"]>
    before?: Maybe<Scalars["String"]>
    first?: Maybe<Scalars["Int"]>
    last?: Maybe<Scalars["Int"]>
    offset?: Maybe<Scalars["Int"]>
    testId?: Maybe<Scalars["UUID"]>
}

export type SeeQuestion = {
    __typename?: "SeeQuestion"
    result: Scalars["String"]
}

export type SeeQuestionInput = {
    questionId: Scalars["UUID"]
    solutionId: Scalars["UUID"]
}

export type SeeSolution = {
    solutionId: Scalars["UUID"]
}

export type SendResetPassword = {
    __typename?: "SendResetPassword"
    result: Scalars["String"]
}

export type SendResetPasswordInput = {
    email: Scalars["String"]
}

/** An enumeration. */
export enum Seniority {
    Junior = "JUNIOR",
    Manager = "MANAGER",
    MidSenior = "MID_SENIOR",
}

export type SignUp = {
    __typename?: "SignUp"
    payload?: Maybe<RecruiterSignUpPayload>
}

export type SignUpInput = {
    email: Scalars["String"]
    firstName: Scalars["String"]
    lastName: Scalars["String"]
    locale: Locale
    phone?: Maybe<Scalars["String"]>
    role: RecruiterRole
}

export type SignUpUser = {
    __typename?: "SignUpUser"
    accountId: Scalars["Int"]
    email: Scalars["String"]
    uuid: Scalars["UUID"]
}

export type SingleChoiceQuestion = BaseTestQuestion &
    BaseTestQuestionWithAnswers & {
        __typename?: "SingleChoiceQuestion"
        possibleAnswers: Array<Maybe<QuestionAnswer>>
        text: Scalars["String"]
        uuid: Scalars["UUID"]
    }

export type Solution = {
    __typename?: "Solution"
    candidate: SolutionCandidate
    createdAt: Scalars["DateTime"]
    responses?: Maybe<Array<Maybe<Response>>>
    score: Scalars["Decimal"]
    seenAt?: Maybe<Scalars["DateTime"]>
    solutionState: SolutionState
    starred: Scalars["Boolean"]
    submittedAt: Scalars["DateTime"]
    summary?: Maybe<Array<Maybe<SummaryEntry>>>
    test: BaseTest
    testRoleName: Scalars["String"]
    timeSpent: Scalars["Int"]
    updatedAt: Scalars["DateTime"]
    uuid: Scalars["UUID"]
}

export type SolutionAttempt = {
    __typename?: "SolutionAttempt"
    availableTime: Scalars["Int"]
    questions: Array<Maybe<TestQuestion>>
    solutionId: Scalars["UUID"]
    suggestedTime: Scalars["Int"]
}

export type SolutionCandidate = {
    __typename?: "SolutionCandidate"
    email: Scalars["String"]
    firstName: Scalars["String"]
    lastName: Scalars["String"]
    phoneNumber: Scalars["String"]
    status: CandidateStatus
    uuid: Scalars["UUID"]
}

export type SolutionConnection = {
    __typename?: "SolutionConnection"
    /** Contains the nodes in this connection. */
    edges: Array<Maybe<SolutionEdge>>
    /** Pagination data for this connection. */
    pageInfo: PageInfo
    /** Total count of available results */
    totalCount: Scalars["Int"]
}

/** A Relay edge containing a `Solution` and its cursor. */
export type SolutionEdge = {
    __typename?: "SolutionEdge"
    /** A cursor for use in pagination */
    cursor: Scalars["String"]
    /** The item at the end of the edge */
    node?: Maybe<BaseSolution>
}

/** An enumeration. */
export enum SolutionState {
    Expired = "EXPIRED",
    Opened = "OPENED",
    Sent = "SENT",
    Started = "STARTED",
    Submitted = "SUBMITTED",
}

export type StarSolution = {
    solutionId: Scalars["UUID"]
    starred: Scalars["Boolean"]
}

export type StartSolution = {
    __typename?: "StartSolution"
    solutionAttempt: SolutionAttempt
}

export type StartSolutionInput = {
    candidateSessionToken: Scalars["String"]
}

export type SubmitResponse = {
    __typename?: "SubmitResponse"
    result: Scalars["String"]
}

export type SubmitResponseInput = {
    answersGiven: Array<AnswerGivenInput>
    questionId: Scalars["UUID"]
    solutionId: Scalars["UUID"]
}

export type SubmitSolution = {
    __typename?: "SubmitSolution"
    result: Scalars["String"]
}

export type SubmitSolutionInput = {
    solutionId: Scalars["UUID"]
}

export type SuggestedCompetencies = {
    __typename?: "SuggestedCompetencies"
    selected: Array<Maybe<SuggestedCompetency>>
}

export type SuggestedCompetency = {
    __typename?: "SuggestedCompetency"
    description: Scalars["String"]
    domain: Scalars["String"]
    icon: Icon
    name: Scalars["String"]
    uuid: Scalars["UUID"]
}

export type SuggestedOpenQuestion = {
    __typename?: "SuggestedOpenQuestion"
    competencyName: Scalars["String"]
    content: Scalars["String"]
    uuid: Scalars["UUID"]
}

export type SuggestedOpenQuestions = {
    __typename?: "SuggestedOpenQuestions"
    suggested: Array<Maybe<SuggestedOpenQuestion>>
}

export type SummaryCompetency = {
    __typename?: "SummaryCompetency"
    name: Scalars["String"]
    uuid: Scalars["UUID"]
}

export type SummaryEntry = {
    __typename?: "SummaryEntry"
    competency: SummaryCompetency
    numberOfQuestions: Scalars["Int"]
    score: Scalars["Decimal"]
}

export type Test = {
    __typename?: "Test"
    /** Owner of the test (HR user / organization) */
    assignedTo?: Maybe<BaseUser>
    availableTime: Scalars["Int"]
    /** Average score of solutions */
    averageSolutionScore?: Maybe<Scalars["Decimal"]>
    /** Average time in seconds spent on solutions */
    averageSolutionTime?: Maybe<Scalars["Int"]>
    averageSummary?: Maybe<Array<Maybe<SummaryEntry>>>
    competencies?: Maybe<Array<Maybe<Competency>>>
    competencyNames: Array<Maybe<Scalars["String"]>>
    /** Number of submitted solutions to the test */
    completedBy: Scalars["Int"]
    createdAt: Scalars["DateTime"]
    description?: Maybe<Scalars["String"]>
    disabledAt?: Maybe<Scalars["DateTime"]>
    experimental?: Maybe<Scalars["Boolean"]>
    locale: Locale
    mostRecentActivityAt?: Maybe<Scalars["DateTime"]>
    mostRecentActivitySecondsAgo?: Maybe<Scalars["Int"]>
    numberOfQuestions: Scalars["Int"]
    organizationName: Scalars["String"]
    questions?: Maybe<Array<Maybe<Question>>>
    requiresExternalTool: Scalars["Boolean"]
    roleName: Scalars["String"]
    seniority: Seniority
    solutionUuids?: Maybe<Array<Maybe<Scalars["UUID"]>>>
    solutionsDistribution: Array<Maybe<Bin>>
    testState: TestState
    testType: TestType
    title: Scalars["String"]
    typeformTestId?: Maybe<Scalars["String"]>
    updatedAt?: Maybe<Scalars["DateTime"]>
    uuid: Scalars["UUID"]
}

export type TestConnection = {
    __typename?: "TestConnection"
    /** Contains the nodes in this connection. */
    edges: Array<Maybe<TestEdge>>
    /** Pagination data for this connection. */
    pageInfo: PageInfo
    /** Total count of available results */
    totalCount: Scalars["Int"]
}

/** A Relay edge containing a `Test` and its cursor. */
export type TestEdge = {
    __typename?: "TestEdge"
    /** A cursor for use in pagination */
    cursor: Scalars["String"]
    /** The item at the end of the edge */
    node?: Maybe<BaseTest>
}

/** An enumeration. */
export enum TestLevel {
    Advanced = "ADVANCED",
    Basic = "BASIC",
}

export type TestMutation = {
    __typename?: "TestMutation"
    result: Scalars["String"]
}

export type TestPreview = {
    __typename?: "TestPreview"
    availableTime: Scalars["Int"]
    competencies?: Maybe<Array<Maybe<Competency>>>
    description?: Maybe<Scalars["String"]>
    locale: Locale
    numberOfQuestions: Scalars["Int"]
    organizationName: Scalars["String"]
    requiresExternalTool: Scalars["Boolean"]
    roleName: Scalars["String"]
    title: Scalars["String"]
    uuid: Scalars["UUID"]
}

export type TestPreviewResult = TestPreview | TestPreviewUnavailable

export type TestPreviewUnavailable = {
    __typename?: "TestPreviewUnavailable"
    reason: Scalars["String"]
}

export type TestQuestion = MultipleChoiceQuestion | NumericQuestion | SingleChoiceQuestion | TextQuestion

/** An enumeration. */
export enum TestState {
    Active = "ACTIVE",
    Disabled = "DISABLED",
    Draft = "DRAFT",
}

/** An enumeration. */
export enum TestType {
    PostCv = "POST_CV",
    PreScreening = "PRE_SCREENING",
}

export type TextQuestion = BaseTestQuestion & {
    __typename?: "TextQuestion"
    text: Scalars["String"]
    uuid: Scalars["UUID"]
}

export type ToggleBlockUser = {
    __typename?: "ToggleBlockUser"
    result?: Maybe<AdminBaseUser>
}

export type TrackInput = {
    eventName: Scalars["String"]
    properties?: Maybe<Scalars["JSONString"]>
}

export type TriggerPasswordReset = {
    __typename?: "TriggerPasswordReset"
    resetToken: Scalars["String"]
}

export type UpdateCandidatesStatus = {
    __typename?: "UpdateCandidatesStatus"
    candidates: Array<Maybe<SolutionCandidate>>
}

export type UpdateCandidatesStatusInput = {
    candidateIds: Array<Maybe<Scalars["UUID"]>>
    status: CandidateStatusInput
}

export type UpdateOpenQuestion = {
    __typename?: "UpdateOpenQuestion"
    content: Scalars["String"]
    uuid: Scalars["UUID"]
}

export type UpdateTest = {
    __typename?: "UpdateTest"
    result?: Maybe<AdminTest>
}

export type UpdateUser = {
    __typename?: "UpdateUser"
    user: AdminBaseUser
}

export type Verify2FaToken = {
    __typename?: "Verify2FAToken"
    intermediateToken: Scalars["String"]
}

export type WizardCompetencyInput = {
    id: Scalars["UUID"]
}

export type WizardCreateTestInput = {
    competencies: Array<Maybe<WizardCompetencyInput>>
    description?: Maybe<Scalars["String"]>
    existingOpenQuestions: Array<Maybe<ExistingOpenQuestion>>
    newOpenQuestions: Array<Maybe<NewOpenQuestion>>
    roleName: Scalars["String"]
    testLevel: TestLevel
    testType: TestType
}

export type WizardTest = {
    __typename?: "WizardTest"
    id: Scalars["UUID"]
    title: Scalars["String"]
}

export type GetAllTestsAdminQueryVariables = Exact<{
    searchPhrase?: Maybe<Scalars["String"]>
}>

export type GetAllTestsAdminQuery = {
    __typename?: "RootQuery"
    adminTestConnection?: Maybe<{
        __typename?: "AdminTestConnection"
        edges: Array<
            Maybe<{
                __typename?: "AdminTestEdge"
                node?: Maybe<{
                    __typename?: "AdminBaseTest"
                    uuid: string
                    testState: TestState
                    createdAt: any
                    roleName: string
                    title: string
                    completedBy: number
                    assignedTo?: Maybe<{
                        __typename?: "AdminBaseUser"
                        uuid: string
                        organizationName?: Maybe<string>
                        email: string
                    }>
                }>
            }>
        >
        pageInfo: { __typename?: "PageInfo"; endCursor?: Maybe<string>; hasNextPage: boolean }
    }>
}

export type GetAllQuestionsAdminQueryVariables = Exact<{
    searchPhrase?: Maybe<Scalars["String"]>
}>

export type GetAllQuestionsAdminQuery = {
    __typename?: "RootQuery"
    adminQuestionConnection?: Maybe<{
        __typename?: "AdminQuestionConnection"
        edges: Array<
            Maybe<{
                __typename?: "AdminQuestionEdge"
                node?: Maybe<{
                    __typename?: "Question"
                    uuid: string
                    content: string
                    difficulty: Difficulty
                    availableTime: number
                    questionType: QuestionType
                    answers?: Maybe<
                        Array<Maybe<{ __typename?: "Answer"; uuid: string; content: string; correct: boolean }>>
                    >
                    competency?: Maybe<{
                        __typename?: "Competency"
                        uuid: string
                        name: string
                        description: string
                        domain: string
                        icon: Icon
                    }>
                }>
            }>
        >
        pageInfo: { __typename?: "PageInfo"; endCursor?: Maybe<string>; hasNextPage: boolean }
    }>
}

export type GetTestAdminQueryVariables = Exact<{
    id: Scalars["UUID"]
}>

export type GetTestAdminQuery = {
    __typename?: "RootQuery"
    adminTest?: Maybe<{
        __typename?: "AdminTest"
        uuid: string
        availableTime: number
        description?: Maybe<string>
        roleName: string
        seniority: Seniority
        testType: TestType
        testState: TestState
        title: string
        typeformTestId?: Maybe<string>
        experimental?: Maybe<boolean>
        questions?: Maybe<
            Array<
                Maybe<{
                    __typename?: "Question"
                    uuid: string
                    content: string
                    difficulty: Difficulty
                    availableTime: number
                    questionType: QuestionType
                    competency?: Maybe<{
                        __typename?: "Competency"
                        uuid: string
                        name: string
                        description: string
                        domain: string
                        icon: Icon
                    }>
                }>
            >
        >
        assignedTo?: Maybe<{ __typename?: "AdminBaseUser"; uuid: string }>
    }>
}

export type CreateTestAdminMutationVariables = Exact<{
    availableTime: Scalars["Int"]
    questions: Array<Maybe<Scalars["UUID"]>> | Maybe<Scalars["UUID"]>
    description: Scalars["String"]
    roleName: Scalars["String"]
    seniority: Seniority
    testType: TestType
    title: Scalars["String"]
    user?: Maybe<Scalars["UUID"]>
}>

export type CreateTestAdminMutation = {
    __typename?: "MutationsRoot"
    adminCreateTest?: Maybe<{ __typename?: "CreateTest"; result?: Maybe<{ __typename?: "AdminTest"; uuid: string }> }>
}

export type UpdateTestAdminMutationVariables = Exact<{
    availableTime?: Maybe<Scalars["Int"]>
    questions?: Maybe<Array<Scalars["UUID"]> | Scalars["UUID"]>
    description?: Maybe<Scalars["String"]>
    roleName?: Maybe<Scalars["String"]>
    seniority?: Maybe<Seniority>
    testType?: Maybe<TestType>
    title?: Maybe<Scalars["String"]>
    experimental?: Maybe<Scalars["Boolean"]>
    testUuid: Scalars["UUID"]
}>

export type UpdateTestAdminMutation = {
    __typename?: "MutationsRoot"
    adminUpdateTest?: Maybe<{ __typename?: "UpdateTest"; result?: Maybe<{ __typename?: "AdminTest"; uuid: string }> }>
}

export type DuplicateTestAdminMutationVariables = Exact<{
    userUuid?: Maybe<Scalars["UUID"]>
    testUuid: Scalars["UUID"]
}>

export type DuplicateTestAdminMutation = {
    __typename?: "MutationsRoot"
    adminDuplicateTest?: Maybe<{
        __typename?: "DuplicateTest"
        result?: Maybe<{ __typename?: "AdminTest"; uuid: string }>
    }>
}

export type GetAllUsersAdminQueryVariables = Exact<{
    searchPhrase?: Maybe<Scalars["String"]>
}>

export type GetAllUsersAdminQuery = {
    __typename?: "RootQuery"
    adminUserConnection?: Maybe<{
        __typename?: "AdminUserConnection"
        edges: Array<
            Maybe<{
                __typename?: "AdminUserEdge"
                node?: Maybe<{
                    __typename?: "BaseUser"
                    uuid: string
                    organizationName?: Maybe<string>
                    firstName: string
                    lastName: string
                    email: string
                    locale: Locale
                    logUrl: string
                    accountLogUrl: string
                }>
            }>
        >
        pageInfo: { __typename?: "PageInfo"; endCursor?: Maybe<string>; hasNextPage: boolean }
    }>
}

export type GetUserAdminQueryVariables = Exact<{
    id: Scalars["UUID"]
}>

export type GetUserAdminQuery = {
    __typename?: "RootQuery"
    adminUser?: Maybe<{
        __typename?: "AdminBaseUser"
        uuid: string
        firstName: string
        lastName: string
        email: string
        organizationName?: Maybe<string>
        locale: Locale
    }>
}

export type PublishTestAdminMutationVariables = Exact<{
    testUuid: Scalars["UUID"]
}>

export type PublishTestAdminMutation = {
    __typename?: "MutationsRoot"
    adminPublishTest?: Maybe<{
        __typename?: "PublishTest"
        result?: Maybe<{ __typename?: "AdminTest"; typeformTestId?: Maybe<string> }>
    }>
}

export type DisableTestAdminMutationVariables = Exact<{
    testUuid: Scalars["UUID"]
}>

export type DisableTestAdminMutation = {
    __typename?: "MutationsRoot"
    adminDisableTest?: Maybe<{ __typename?: "DisableTest"; result?: Maybe<{ __typename?: "AdminTest"; uuid: string }> }>
}

export type EnableTestAdminMutationVariables = Exact<{
    testUuid: Scalars["UUID"]
}>

export type EnableTestAdminMutation = {
    __typename?: "MutationsRoot"
    adminEnableTest?: Maybe<{ __typename?: "EnableTest"; result?: Maybe<{ __typename?: "AdminTest"; uuid: string }> }>
}

export type CreateUserAdminMutationVariables = Exact<{
    email: Scalars["String"]
    firstName: Scalars["String"]
    lastName: Scalars["String"]
    organizationName: Scalars["String"]
    locale: Locale
}>

export type CreateUserAdminMutation = {
    __typename?: "MutationsRoot"
    adminCreateUser?: Maybe<{ __typename?: "CreateUser"; user: { __typename?: "AdminBaseUser"; uuid: string } }>
}

export type UpdateUserAdminMutationVariables = Exact<{
    id: Scalars["UUID"]
    email: Scalars["String"]
    firstName: Scalars["String"]
    lastName: Scalars["String"]
    organizationName: Scalars["String"]
    locale: Locale
}>

export type UpdateUserAdminMutation = {
    __typename?: "MutationsRoot"
    adminUpdateUser?: Maybe<{ __typename?: "UpdateUser"; user: { __typename?: "AdminBaseUser"; uuid: string } }>
}

export type TriggerPasswordResetAdminMutationVariables = Exact<{
    uuid: Scalars["UUID"]
}>

export type TriggerPasswordResetAdminMutation = {
    __typename?: "MutationsRoot"
    adminTriggerPasswordReset?: Maybe<{ __typename?: "TriggerPasswordReset"; resetToken: string }>
}

export type DeleteTestAdminMutationVariables = Exact<{
    uuid: Scalars["UUID"]
}>

export type DeleteTestAdminMutation = {
    __typename?: "MutationsRoot"
    adminDeleteTest?: Maybe<{ __typename?: "DeleteTest"; result?: Maybe<{ __typename?: "AdminTest"; uuid: string }> }>
}

export type ImpersonateUserAdminMutationVariables = Exact<{
    uuid: Scalars["UUID"]
}>

export type ImpersonateUserAdminMutation = {
    __typename?: "MutationsRoot"
    adminImpersonateUser?: Maybe<{ __typename?: "ImpersonateUser"; authorizationToken: string }>
}

export type CreateOpenQuestionAdminMutationVariables = Exact<{
    content: Scalars["String"]
}>

export type CreateOpenQuestionAdminMutation = {
    __typename?: "MutationsRoot"
    adminCreateOpenQuestion?: Maybe<{ __typename?: "CreateOpenQuestion"; uuid: string }>
}

export type GetAllTestsQueryVariables = Exact<{
    searchPhrase?: Maybe<Scalars["String"]>
}>

export type GetAllTestsQuery = {
    __typename?: "RootQuery"
    testConnection?: Maybe<{
        __typename?: "TestConnection"
        edges: Array<
            Maybe<{
                __typename?: "TestEdge"
                node?: Maybe<{
                    __typename?: "BaseTest"
                    uuid: string
                    testState: TestState
                    createdAt: any
                    mostRecentActivityAt?: Maybe<any>
                    mostRecentActivitySecondsAgo?: Maybe<number>
                    updatedAt?: Maybe<any>
                    roleName: string
                    title: string
                    typeformTestId?: Maybe<string>
                    completedBy: number
                    testType: TestType
                }>
            }>
        >
        pageInfo: { __typename?: "PageInfo"; endCursor?: Maybe<string>; hasNextPage: boolean }
    }>
}

export type GetSolutionQueryVariables = Exact<{
    uuid: Scalars["UUID"]
}>

export type GetSolutionQuery = {
    __typename?: "RootQuery"
    solution?: Maybe<{
        __typename?: "Solution"
        uuid: string
        testRoleName: string
        starred: boolean
        solutionState: SolutionState
        submittedAt: any
        score: any
        timeSpent: number
        seenAt?: Maybe<any>
        responses?: Maybe<
            Array<
                Maybe<{
                    __typename?: "Response"
                    uuid: string
                    index?: Maybe<number>
                    outdated: boolean
                    question?: Maybe<{
                        __typename?: "Question"
                        uuid: string
                        custom: boolean
                        questionType: QuestionType
                        content: string
                        requiresExternalTool?: Maybe<string>
                        appendix?: Maybe<string>
                        availableTime: number
                        difficulty: Difficulty
                        questionStatus: QuestionStatus
                        competency?: Maybe<{
                            __typename?: "Competency"
                            uuid: string
                            name: string
                            description: string
                            domain: string
                            icon: Icon
                        }>
                        answers?: Maybe<
                            Array<Maybe<{ __typename?: "Answer"; uuid: string; correct: boolean; content: string }>>
                        >
                    }>
                    answersGiven?: Maybe<
                        Array<
                            Maybe<{
                                __typename?: "AnswerGiven"
                                uuid: string
                                correct?: Maybe<boolean>
                                userSelected: boolean
                                content?: Maybe<string>
                                answer?: Maybe<{
                                    __typename?: "Answer"
                                    uuid: string
                                    correct: boolean
                                    content: string
                                }>
                            }>
                        >
                    >
                }>
            >
        >
        test: {
            __typename?: "BaseTest"
            uuid: string
            availableTime: number
            roleName: string
            title: string
            createdAt: any
            testType: TestType
            solutionsDistribution: Array<Maybe<{ __typename?: "Bin"; label: string; value: number }>>
        }
        summary?: Maybe<
            Array<
                Maybe<{
                    __typename?: "SummaryEntry"
                    numberOfQuestions: number
                    score: any
                    competency: { __typename?: "SummaryCompetency"; uuid: string; name: string }
                }>
            >
        >
    }>
}

export type StarSolutionMutationVariables = Exact<{
    id: Scalars["UUID"]
    starred: Scalars["Boolean"]
}>

export type StarSolutionMutation = {
    __typename?: "MutationsRoot"
    recruiterStarSolution?: Maybe<{ __typename?: "RecruiterStarSolution"; result: string }>
}

export type DeleteTestMutationVariables = Exact<{
    id: Scalars["UUID"]
}>

export type DeleteTestMutation = {
    __typename?: "MutationsRoot"
    recruiterDeleteTest?: Maybe<{ __typename?: "RecruiterDeleteTest"; result: string }>
}

export type AuthenticateUserMutationVariables = Exact<{
    email: Scalars["String"]
    password: Scalars["String"]
}>

export type AuthenticateUserMutation = {
    __typename?: "MutationsRoot"
    auth?: Maybe<{ __typename?: "Auth"; authorizationToken: string }>
}

export type ResetPasswordMutationVariables = Exact<{
    email: SendResetPasswordInput
}>

export type ResetPasswordMutation = {
    __typename?: "MutationsRoot"
    sendResetPassword?: Maybe<{ __typename?: "SendResetPassword"; result: string }>
}

export type SetPasswordMutationVariables = Exact<{
    password: Scalars["String"]
    resetToken: Scalars["String"]
}>

export type SetPasswordMutation = {
    __typename?: "MutationsRoot"
    resetPassword?: Maybe<{ __typename?: "ResetPassword"; authorizationToken: string }>
}

export type GetTestQueryVariables = Exact<{
    id: Scalars["UUID"]
}>

export type GetTestQuery = {
    __typename?: "RootQuery"
    test?: Maybe<{
        __typename?: "Test"
        uuid: string
        testType: TestType
        availableTime: number
        organizationName: string
        createdAt: any
        description?: Maybe<string>
        roleName: string
        seniority: Seniority
        testState: TestState
        title: string
        typeformTestId?: Maybe<string>
        solutionUuids?: Maybe<Array<Maybe<string>>>
        averageSolutionTime?: Maybe<number>
        averageSolutionScore?: Maybe<any>
        completedBy: number
        questions?: Maybe<
            Array<
                Maybe<{
                    __typename?: "Question"
                    uuid: string
                    content: string
                    difficulty: Difficulty
                    availableTime: number
                    competency?: Maybe<{
                        __typename?: "Competency"
                        uuid: string
                        name: string
                        description: string
                        domain: string
                        icon: Icon
                    }>
                }>
            >
        >
        competencies?: Maybe<
            Array<
                Maybe<{
                    __typename?: "Competency"
                    uuid: string
                    name: string
                    description: string
                    domain: string
                    icon: Icon
                }>
            >
        >
        assignedTo?: Maybe<{
            __typename?: "BaseUser"
            uuid: string
            firstName: string
            lastName: string
            organizationName?: Maybe<string>
        }>
        averageSummary?: Maybe<
            Array<
                Maybe<{
                    __typename?: "SummaryEntry"
                    numberOfQuestions: number
                    score: any
                    competency: { __typename?: "SummaryCompetency"; uuid: string; name: string }
                }>
            >
        >
        solutionsDistribution: Array<Maybe<{ __typename?: "Bin"; label: string; value: number }>>
    }>
}

export type GetTestCandidateQueryVariables = Exact<{
    id: Scalars["UUID"]
}>

export type GetTestCandidateQuery = {
    __typename?: "RootQuery"
    candidateTestPreview?: Maybe<
        | {
              __typename: "TestPreview"
              uuid: string
              title: string
              roleName: string
              availableTime: number
              numberOfQuestions: number
              description?: Maybe<string>
              organizationName: string
              requiresExternalTool: boolean
              locale: Locale
              competencies?: Maybe<
                  Array<
                      Maybe<{
                          __typename?: "Competency"
                          uuid: string
                          name: string
                          description: string
                          domain: string
                          icon: Icon
                      }>
                  >
              >
          }
        | { __typename: "TestPreviewUnavailable"; reason: string }
    >
}

export type Issue2FaTokenMutationVariables = Exact<{
    testId: Scalars["UUID"]
    phoneNumber: Scalars["String"]
}>

export type Issue2FaTokenMutation = {
    __typename?: "MutationsRoot"
    candidateIssue2faToken?: Maybe<{ __typename?: "Issue2FAToken"; result: string }>
}

export type Verify2faTokenMutationVariables = Exact<{
    testId: Scalars["UUID"]
    phoneNumber: Scalars["String"]
    verificationToken: Scalars["String"]
}>

export type Verify2faTokenMutation = {
    __typename?: "MutationsRoot"
    candidateVerify2faToken?: Maybe<{ __typename?: "Verify2FAToken"; intermediateToken: string }>
}

export type RegisterCandidateMutationVariables = Exact<{
    email: Scalars["String"]
    firstName: Scalars["String"]
    intermediateToken: Scalars["String"]
    lastName: Scalars["String"]
}>

export type RegisterCandidateMutation = {
    __typename?: "MutationsRoot"
    registerCandidate?: Maybe<{ __typename?: "RegisterCandidate"; candidateSessionToken: string }>
}

export type RecruiterSignUpMutationVariables = Exact<{
    signUp: SignUpInput
}>

export type RecruiterSignUpMutation = {
    __typename?: "MutationsRoot"
    recruiterSignUp?: Maybe<{
        __typename?: "SignUp"
        payload?: Maybe<{
            __typename?: "RecruiterSignUpPayload"
            status: string
            user?: Maybe<{ __typename?: "SignUpUser"; email: string; uuid: string; accountId: number }>
            errors: Array<Maybe<{ __typename?: "Error"; name: string; messages?: Maybe<Array<Maybe<string>>> }>>
        }>
    }>
}

export type CandidateAttemptSolutionMutationVariables = Exact<{
    candidateSessionToken: Scalars["String"]
}>

export type CandidateAttemptSolutionMutation = {
    __typename?: "MutationsRoot"
    candidateAttemptSolution?: Maybe<{ __typename?: "CandidateAttemptSolution"; typeformTestId: string }>
}

export type GetCandidateSolutionPreviewQueryVariables = Exact<{
    sessionToken: Scalars["String"]
}>

export type GetCandidateSolutionPreviewQuery = {
    __typename?: "RootQuery"
    candidateSolutionPreview?: Maybe<{
        __typename?: "CandidateSolutionPreview"
        solutionState: SolutionState
        score?: Maybe<any>
        timeSpent?: Maybe<number>
        test: { __typename?: "TestPreview"; uuid: string; organizationName: string; roleName: string }
        candidate: { __typename?: "CandidatePreview"; uuid: string; email: string; firstName: string }
        summary?: Maybe<
            Array<
                Maybe<{
                    __typename?: "SummaryEntry"
                    score: any
                    competency: { __typename?: "SummaryCompetency"; uuid: string; name: string }
                }>
            >
        >
    }>
}

export type CandidateGiveFeedbackMutationVariables = Exact<{
    candidateSessionToken: Scalars["String"]
    feedbackScore?: Maybe<Scalars["Decimal"]>
    feedbackText?: Maybe<Scalars["String"]>
}>

export type CandidateGiveFeedbackMutation = {
    __typename?: "MutationsRoot"
    candidateGiveFeedback?: Maybe<{ __typename?: "CandidateGiveFeedback"; result: string }>
}

export const GetAllTestsAdminDocument = gql`
    query GetAllTestsAdmin($searchPhrase: String) {
        adminTestConnection(search: $searchPhrase, orderBy: "latestSolutionDate", descending: true) {
            edges {
                node {
                    uuid
                    testState
                    createdAt
                    roleName
                    title
                    completedBy
                    assignedTo {
                        uuid
                        organizationName
                        email
                    }
                }
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`

/**
 * __useGetAllTestsAdminQuery__
 *
 * To run a query within a React component, call `useGetAllTestsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTestsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTestsAdminQuery({
 *   variables: {
 *      searchPhrase: // value for 'searchPhrase'
 *   },
 * });
 */
export function useGetAllTestsAdminQuery(
    baseOptions?: Apollo.QueryHookOptions<GetAllTestsAdminQuery, GetAllTestsAdminQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetAllTestsAdminQuery, GetAllTestsAdminQueryVariables>(GetAllTestsAdminDocument, options)
}
export function useGetAllTestsAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetAllTestsAdminQuery, GetAllTestsAdminQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetAllTestsAdminQuery, GetAllTestsAdminQueryVariables>(GetAllTestsAdminDocument, options)
}
export type GetAllTestsAdminQueryHookResult = ReturnType<typeof useGetAllTestsAdminQuery>
export type GetAllTestsAdminLazyQueryHookResult = ReturnType<typeof useGetAllTestsAdminLazyQuery>
export type GetAllTestsAdminQueryResult = Apollo.QueryResult<GetAllTestsAdminQuery, GetAllTestsAdminQueryVariables>
export const GetAllQuestionsAdminDocument = gql`
    query GetAllQuestionsAdmin($searchPhrase: String) {
        adminQuestionConnection(search: $searchPhrase) {
            edges {
                node {
                    uuid
                    content
                    difficulty
                    availableTime
                    questionType
                    answers {
                        uuid
                        content
                        correct
                    }
                    competency {
                        uuid
                        name
                        description
                        domain
                        icon
                    }
                }
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`

/**
 * __useGetAllQuestionsAdminQuery__
 *
 * To run a query within a React component, call `useGetAllQuestionsAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllQuestionsAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllQuestionsAdminQuery({
 *   variables: {
 *      searchPhrase: // value for 'searchPhrase'
 *   },
 * });
 */
export function useGetAllQuestionsAdminQuery(
    baseOptions?: Apollo.QueryHookOptions<GetAllQuestionsAdminQuery, GetAllQuestionsAdminQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetAllQuestionsAdminQuery, GetAllQuestionsAdminQueryVariables>(
        GetAllQuestionsAdminDocument,
        options
    )
}
export function useGetAllQuestionsAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetAllQuestionsAdminQuery, GetAllQuestionsAdminQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetAllQuestionsAdminQuery, GetAllQuestionsAdminQueryVariables>(
        GetAllQuestionsAdminDocument,
        options
    )
}
export type GetAllQuestionsAdminQueryHookResult = ReturnType<typeof useGetAllQuestionsAdminQuery>
export type GetAllQuestionsAdminLazyQueryHookResult = ReturnType<typeof useGetAllQuestionsAdminLazyQuery>
export type GetAllQuestionsAdminQueryResult = Apollo.QueryResult<
    GetAllQuestionsAdminQuery,
    GetAllQuestionsAdminQueryVariables
>
export const GetTestAdminDocument = gql`
    query GetTestAdmin($id: UUID!) {
        adminTest(uuid: $id) {
            uuid
            availableTime
            questions {
                uuid
                content
                difficulty
                availableTime
                questionType
                competency {
                    uuid
                    name
                    description
                    domain
                    icon
                }
            }
            description
            roleName
            seniority
            testType
            testState
            title
            typeformTestId
            experimental
            assignedTo {
                uuid
            }
        }
    }
`

/**
 * __useGetTestAdminQuery__
 *
 * To run a query within a React component, call `useGetTestAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTestAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTestAdminQuery(
    baseOptions: Apollo.QueryHookOptions<GetTestAdminQuery, GetTestAdminQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetTestAdminQuery, GetTestAdminQueryVariables>(GetTestAdminDocument, options)
}
export function useGetTestAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetTestAdminQuery, GetTestAdminQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetTestAdminQuery, GetTestAdminQueryVariables>(GetTestAdminDocument, options)
}
export type GetTestAdminQueryHookResult = ReturnType<typeof useGetTestAdminQuery>
export type GetTestAdminLazyQueryHookResult = ReturnType<typeof useGetTestAdminLazyQuery>
export type GetTestAdminQueryResult = Apollo.QueryResult<GetTestAdminQuery, GetTestAdminQueryVariables>
export const CreateTestAdminDocument = gql`
    mutation CreateTestAdmin(
        $availableTime: Int!
        $questions: [UUID]!
        $description: String!
        $roleName: String!
        $seniority: Seniority!
        $testType: TestType!
        $title: String!
        $user: UUID = null
    ) {
        adminCreateTest(
            availableTime: $availableTime
            questions: $questions
            description: $description
            roleName: $roleName
            seniority: $seniority
            testType: $testType
            title: $title
            user: $user
        ) {
            result {
                uuid
            }
        }
    }
`
export type CreateTestAdminMutationFn = Apollo.MutationFunction<
    CreateTestAdminMutation,
    CreateTestAdminMutationVariables
>

/**
 * __useCreateTestAdminMutation__
 *
 * To run a mutation, you first call `useCreateTestAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTestAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTestAdminMutation, { data, loading, error }] = useCreateTestAdminMutation({
 *   variables: {
 *      availableTime: // value for 'availableTime'
 *      questions: // value for 'questions'
 *      description: // value for 'description'
 *      roleName: // value for 'roleName'
 *      seniority: // value for 'seniority'
 *      testType: // value for 'testType'
 *      title: // value for 'title'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateTestAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateTestAdminMutation, CreateTestAdminMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<CreateTestAdminMutation, CreateTestAdminMutationVariables>(
        CreateTestAdminDocument,
        options
    )
}
export type CreateTestAdminMutationHookResult = ReturnType<typeof useCreateTestAdminMutation>
export type CreateTestAdminMutationResult = Apollo.MutationResult<CreateTestAdminMutation>
export type CreateTestAdminMutationOptions = Apollo.BaseMutationOptions<
    CreateTestAdminMutation,
    CreateTestAdminMutationVariables
>
export const UpdateTestAdminDocument = gql`
    mutation UpdateTestAdmin(
        $availableTime: Int
        $questions: [UUID!]
        $description: String
        $roleName: String
        $seniority: Seniority
        $testType: TestType
        $title: String
        $experimental: Boolean
        $testUuid: UUID!
    ) {
        adminUpdateTest(
            availableTime: $availableTime
            questions: $questions
            description: $description
            roleName: $roleName
            seniority: $seniority
            testType: $testType
            title: $title
            experimental: $experimental
            testUuid: $testUuid
        ) {
            result {
                uuid
            }
        }
    }
`
export type UpdateTestAdminMutationFn = Apollo.MutationFunction<
    UpdateTestAdminMutation,
    UpdateTestAdminMutationVariables
>

/**
 * __useUpdateTestAdminMutation__
 *
 * To run a mutation, you first call `useUpdateTestAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTestAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTestAdminMutation, { data, loading, error }] = useUpdateTestAdminMutation({
 *   variables: {
 *      availableTime: // value for 'availableTime'
 *      questions: // value for 'questions'
 *      description: // value for 'description'
 *      roleName: // value for 'roleName'
 *      seniority: // value for 'seniority'
 *      testType: // value for 'testType'
 *      title: // value for 'title'
 *      experimental: // value for 'experimental'
 *      testUuid: // value for 'testUuid'
 *   },
 * });
 */
export function useUpdateTestAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateTestAdminMutation, UpdateTestAdminMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<UpdateTestAdminMutation, UpdateTestAdminMutationVariables>(
        UpdateTestAdminDocument,
        options
    )
}
export type UpdateTestAdminMutationHookResult = ReturnType<typeof useUpdateTestAdminMutation>
export type UpdateTestAdminMutationResult = Apollo.MutationResult<UpdateTestAdminMutation>
export type UpdateTestAdminMutationOptions = Apollo.BaseMutationOptions<
    UpdateTestAdminMutation,
    UpdateTestAdminMutationVariables
>
export const DuplicateTestAdminDocument = gql`
    mutation DuplicateTestAdmin($userUuid: UUID, $testUuid: UUID!) {
        adminDuplicateTest(newUser: $userUuid, testUuid: $testUuid) {
            result {
                uuid
            }
        }
    }
`
export type DuplicateTestAdminMutationFn = Apollo.MutationFunction<
    DuplicateTestAdminMutation,
    DuplicateTestAdminMutationVariables
>

/**
 * __useDuplicateTestAdminMutation__
 *
 * To run a mutation, you first call `useDuplicateTestAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateTestAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateTestAdminMutation, { data, loading, error }] = useDuplicateTestAdminMutation({
 *   variables: {
 *      userUuid: // value for 'userUuid'
 *      testUuid: // value for 'testUuid'
 *   },
 * });
 */
export function useDuplicateTestAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<DuplicateTestAdminMutation, DuplicateTestAdminMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<DuplicateTestAdminMutation, DuplicateTestAdminMutationVariables>(
        DuplicateTestAdminDocument,
        options
    )
}
export type DuplicateTestAdminMutationHookResult = ReturnType<typeof useDuplicateTestAdminMutation>
export type DuplicateTestAdminMutationResult = Apollo.MutationResult<DuplicateTestAdminMutation>
export type DuplicateTestAdminMutationOptions = Apollo.BaseMutationOptions<
    DuplicateTestAdminMutation,
    DuplicateTestAdminMutationVariables
>
export const GetAllUsersAdminDocument = gql`
    query GetAllUsersAdmin($searchPhrase: String) {
        adminUserConnection(search: $searchPhrase) {
            edges {
                node {
                    uuid
                    organizationName
                    firstName
                    lastName
                    email
                    locale
                    logUrl
                    accountLogUrl
                }
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`

/**
 * __useGetAllUsersAdminQuery__
 *
 * To run a query within a React component, call `useGetAllUsersAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersAdminQuery({
 *   variables: {
 *      searchPhrase: // value for 'searchPhrase'
 *   },
 * });
 */
export function useGetAllUsersAdminQuery(
    baseOptions?: Apollo.QueryHookOptions<GetAllUsersAdminQuery, GetAllUsersAdminQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetAllUsersAdminQuery, GetAllUsersAdminQueryVariables>(GetAllUsersAdminDocument, options)
}
export function useGetAllUsersAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetAllUsersAdminQuery, GetAllUsersAdminQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetAllUsersAdminQuery, GetAllUsersAdminQueryVariables>(GetAllUsersAdminDocument, options)
}
export type GetAllUsersAdminQueryHookResult = ReturnType<typeof useGetAllUsersAdminQuery>
export type GetAllUsersAdminLazyQueryHookResult = ReturnType<typeof useGetAllUsersAdminLazyQuery>
export type GetAllUsersAdminQueryResult = Apollo.QueryResult<GetAllUsersAdminQuery, GetAllUsersAdminQueryVariables>
export const GetUserAdminDocument = gql`
    query GetUserAdmin($id: UUID!) {
        adminUser(uuid: $id) {
            uuid
            firstName
            lastName
            email
            organizationName
            locale
        }
    }
`

/**
 * __useGetUserAdminQuery__
 *
 * To run a query within a React component, call `useGetUserAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserAdminQuery(
    baseOptions: Apollo.QueryHookOptions<GetUserAdminQuery, GetUserAdminQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetUserAdminQuery, GetUserAdminQueryVariables>(GetUserAdminDocument, options)
}
export function useGetUserAdminLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetUserAdminQuery, GetUserAdminQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetUserAdminQuery, GetUserAdminQueryVariables>(GetUserAdminDocument, options)
}
export type GetUserAdminQueryHookResult = ReturnType<typeof useGetUserAdminQuery>
export type GetUserAdminLazyQueryHookResult = ReturnType<typeof useGetUserAdminLazyQuery>
export type GetUserAdminQueryResult = Apollo.QueryResult<GetUserAdminQuery, GetUserAdminQueryVariables>
export const PublishTestAdminDocument = gql`
    mutation PublishTestAdmin($testUuid: UUID!) {
        adminPublishTest(testUuid: $testUuid) {
            result {
                typeformTestId
            }
        }
    }
`
export type PublishTestAdminMutationFn = Apollo.MutationFunction<
    PublishTestAdminMutation,
    PublishTestAdminMutationVariables
>

/**
 * __usePublishTestAdminMutation__
 *
 * To run a mutation, you first call `usePublishTestAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishTestAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishTestAdminMutation, { data, loading, error }] = usePublishTestAdminMutation({
 *   variables: {
 *      testUuid: // value for 'testUuid'
 *   },
 * });
 */
export function usePublishTestAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<PublishTestAdminMutation, PublishTestAdminMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<PublishTestAdminMutation, PublishTestAdminMutationVariables>(
        PublishTestAdminDocument,
        options
    )
}
export type PublishTestAdminMutationHookResult = ReturnType<typeof usePublishTestAdminMutation>
export type PublishTestAdminMutationResult = Apollo.MutationResult<PublishTestAdminMutation>
export type PublishTestAdminMutationOptions = Apollo.BaseMutationOptions<
    PublishTestAdminMutation,
    PublishTestAdminMutationVariables
>
export const DisableTestAdminDocument = gql`
    mutation DisableTestAdmin($testUuid: UUID!) {
        adminDisableTest(testUuid: $testUuid) {
            result {
                uuid
            }
        }
    }
`
export type DisableTestAdminMutationFn = Apollo.MutationFunction<
    DisableTestAdminMutation,
    DisableTestAdminMutationVariables
>

/**
 * __useDisableTestAdminMutation__
 *
 * To run a mutation, you first call `useDisableTestAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableTestAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableTestAdminMutation, { data, loading, error }] = useDisableTestAdminMutation({
 *   variables: {
 *      testUuid: // value for 'testUuid'
 *   },
 * });
 */
export function useDisableTestAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<DisableTestAdminMutation, DisableTestAdminMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<DisableTestAdminMutation, DisableTestAdminMutationVariables>(
        DisableTestAdminDocument,
        options
    )
}
export type DisableTestAdminMutationHookResult = ReturnType<typeof useDisableTestAdminMutation>
export type DisableTestAdminMutationResult = Apollo.MutationResult<DisableTestAdminMutation>
export type DisableTestAdminMutationOptions = Apollo.BaseMutationOptions<
    DisableTestAdminMutation,
    DisableTestAdminMutationVariables
>
export const EnableTestAdminDocument = gql`
    mutation EnableTestAdmin($testUuid: UUID!) {
        adminEnableTest(testUuid: $testUuid) {
            result {
                uuid
            }
        }
    }
`
export type EnableTestAdminMutationFn = Apollo.MutationFunction<
    EnableTestAdminMutation,
    EnableTestAdminMutationVariables
>

/**
 * __useEnableTestAdminMutation__
 *
 * To run a mutation, you first call `useEnableTestAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableTestAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableTestAdminMutation, { data, loading, error }] = useEnableTestAdminMutation({
 *   variables: {
 *      testUuid: // value for 'testUuid'
 *   },
 * });
 */
export function useEnableTestAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<EnableTestAdminMutation, EnableTestAdminMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<EnableTestAdminMutation, EnableTestAdminMutationVariables>(
        EnableTestAdminDocument,
        options
    )
}
export type EnableTestAdminMutationHookResult = ReturnType<typeof useEnableTestAdminMutation>
export type EnableTestAdminMutationResult = Apollo.MutationResult<EnableTestAdminMutation>
export type EnableTestAdminMutationOptions = Apollo.BaseMutationOptions<
    EnableTestAdminMutation,
    EnableTestAdminMutationVariables
>
export const CreateUserAdminDocument = gql`
    mutation CreateUserAdmin(
        $email: String!
        $firstName: String!
        $lastName: String!
        $organizationName: String!
        $locale: Locale!
    ) {
        adminCreateUser(
            email: $email
            firstName: $firstName
            lastName: $lastName
            organizationName: $organizationName
            locale: $locale
        ) {
            user {
                uuid
            }
        }
    }
`
export type CreateUserAdminMutationFn = Apollo.MutationFunction<
    CreateUserAdminMutation,
    CreateUserAdminMutationVariables
>

/**
 * __useCreateUserAdminMutation__
 *
 * To run a mutation, you first call `useCreateUserAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserAdminMutation, { data, loading, error }] = useCreateUserAdminMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      organizationName: // value for 'organizationName'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCreateUserAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateUserAdminMutation, CreateUserAdminMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<CreateUserAdminMutation, CreateUserAdminMutationVariables>(
        CreateUserAdminDocument,
        options
    )
}
export type CreateUserAdminMutationHookResult = ReturnType<typeof useCreateUserAdminMutation>
export type CreateUserAdminMutationResult = Apollo.MutationResult<CreateUserAdminMutation>
export type CreateUserAdminMutationOptions = Apollo.BaseMutationOptions<
    CreateUserAdminMutation,
    CreateUserAdminMutationVariables
>
export const UpdateUserAdminDocument = gql`
    mutation UpdateUserAdmin(
        $id: UUID!
        $email: String!
        $firstName: String!
        $lastName: String!
        $organizationName: String!
        $locale: Locale!
    ) {
        adminUpdateUser(
            id: $id
            email: $email
            firstName: $firstName
            lastName: $lastName
            organizationName: $organizationName
            locale: $locale
        ) {
            user {
                uuid
            }
        }
    }
`
export type UpdateUserAdminMutationFn = Apollo.MutationFunction<
    UpdateUserAdminMutation,
    UpdateUserAdminMutationVariables
>

/**
 * __useUpdateUserAdminMutation__
 *
 * To run a mutation, you first call `useUpdateUserAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAdminMutation, { data, loading, error }] = useUpdateUserAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      organizationName: // value for 'organizationName'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useUpdateUserAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<UpdateUserAdminMutation, UpdateUserAdminMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<UpdateUserAdminMutation, UpdateUserAdminMutationVariables>(
        UpdateUserAdminDocument,
        options
    )
}
export type UpdateUserAdminMutationHookResult = ReturnType<typeof useUpdateUserAdminMutation>
export type UpdateUserAdminMutationResult = Apollo.MutationResult<UpdateUserAdminMutation>
export type UpdateUserAdminMutationOptions = Apollo.BaseMutationOptions<
    UpdateUserAdminMutation,
    UpdateUserAdminMutationVariables
>
export const TriggerPasswordResetAdminDocument = gql`
    mutation TriggerPasswordResetAdmin($uuid: UUID!) {
        adminTriggerPasswordReset(uuid: $uuid) {
            resetToken
        }
    }
`
export type TriggerPasswordResetAdminMutationFn = Apollo.MutationFunction<
    TriggerPasswordResetAdminMutation,
    TriggerPasswordResetAdminMutationVariables
>

/**
 * __useTriggerPasswordResetAdminMutation__
 *
 * To run a mutation, you first call `useTriggerPasswordResetAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerPasswordResetAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerPasswordResetAdminMutation, { data, loading, error }] = useTriggerPasswordResetAdminMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useTriggerPasswordResetAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<
        TriggerPasswordResetAdminMutation,
        TriggerPasswordResetAdminMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<TriggerPasswordResetAdminMutation, TriggerPasswordResetAdminMutationVariables>(
        TriggerPasswordResetAdminDocument,
        options
    )
}
export type TriggerPasswordResetAdminMutationHookResult = ReturnType<typeof useTriggerPasswordResetAdminMutation>
export type TriggerPasswordResetAdminMutationResult = Apollo.MutationResult<TriggerPasswordResetAdminMutation>
export type TriggerPasswordResetAdminMutationOptions = Apollo.BaseMutationOptions<
    TriggerPasswordResetAdminMutation,
    TriggerPasswordResetAdminMutationVariables
>
export const DeleteTestAdminDocument = gql`
    mutation DeleteTestAdmin($uuid: UUID!) {
        adminDeleteTest(testUuid: $uuid) {
            result {
                uuid
            }
        }
    }
`
export type DeleteTestAdminMutationFn = Apollo.MutationFunction<
    DeleteTestAdminMutation,
    DeleteTestAdminMutationVariables
>

/**
 * __useDeleteTestAdminMutation__
 *
 * To run a mutation, you first call `useDeleteTestAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTestAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTestAdminMutation, { data, loading, error }] = useDeleteTestAdminMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteTestAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteTestAdminMutation, DeleteTestAdminMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<DeleteTestAdminMutation, DeleteTestAdminMutationVariables>(
        DeleteTestAdminDocument,
        options
    )
}
export type DeleteTestAdminMutationHookResult = ReturnType<typeof useDeleteTestAdminMutation>
export type DeleteTestAdminMutationResult = Apollo.MutationResult<DeleteTestAdminMutation>
export type DeleteTestAdminMutationOptions = Apollo.BaseMutationOptions<
    DeleteTestAdminMutation,
    DeleteTestAdminMutationVariables
>
export const ImpersonateUserAdminDocument = gql`
    mutation ImpersonateUserAdmin($uuid: UUID!) {
        adminImpersonateUser(userId: $uuid) {
            authorizationToken
        }
    }
`
export type ImpersonateUserAdminMutationFn = Apollo.MutationFunction<
    ImpersonateUserAdminMutation,
    ImpersonateUserAdminMutationVariables
>

/**
 * __useImpersonateUserAdminMutation__
 *
 * To run a mutation, you first call `useImpersonateUserAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImpersonateUserAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [impersonateUserAdminMutation, { data, loading, error }] = useImpersonateUserAdminMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useImpersonateUserAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<ImpersonateUserAdminMutation, ImpersonateUserAdminMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<ImpersonateUserAdminMutation, ImpersonateUserAdminMutationVariables>(
        ImpersonateUserAdminDocument,
        options
    )
}
export type ImpersonateUserAdminMutationHookResult = ReturnType<typeof useImpersonateUserAdminMutation>
export type ImpersonateUserAdminMutationResult = Apollo.MutationResult<ImpersonateUserAdminMutation>
export type ImpersonateUserAdminMutationOptions = Apollo.BaseMutationOptions<
    ImpersonateUserAdminMutation,
    ImpersonateUserAdminMutationVariables
>
export const CreateOpenQuestionAdminDocument = gql`
    mutation CreateOpenQuestionAdmin($content: String!) {
        adminCreateOpenQuestion(content: $content) {
            uuid
        }
    }
`
export type CreateOpenQuestionAdminMutationFn = Apollo.MutationFunction<
    CreateOpenQuestionAdminMutation,
    CreateOpenQuestionAdminMutationVariables
>

/**
 * __useCreateOpenQuestionAdminMutation__
 *
 * To run a mutation, you first call `useCreateOpenQuestionAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOpenQuestionAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOpenQuestionAdminMutation, { data, loading, error }] = useCreateOpenQuestionAdminMutation({
 *   variables: {
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateOpenQuestionAdminMutation(
    baseOptions?: Apollo.MutationHookOptions<CreateOpenQuestionAdminMutation, CreateOpenQuestionAdminMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<CreateOpenQuestionAdminMutation, CreateOpenQuestionAdminMutationVariables>(
        CreateOpenQuestionAdminDocument,
        options
    )
}
export type CreateOpenQuestionAdminMutationHookResult = ReturnType<typeof useCreateOpenQuestionAdminMutation>
export type CreateOpenQuestionAdminMutationResult = Apollo.MutationResult<CreateOpenQuestionAdminMutation>
export type CreateOpenQuestionAdminMutationOptions = Apollo.BaseMutationOptions<
    CreateOpenQuestionAdminMutation,
    CreateOpenQuestionAdminMutationVariables
>
export const GetAllTestsDocument = gql`
    query GetAllTests($searchPhrase: String) {
        testConnection(search: $searchPhrase) {
            edges {
                node {
                    uuid
                    testState
                    createdAt
                    mostRecentActivityAt
                    mostRecentActivitySecondsAgo
                    updatedAt
                    roleName
                    title
                    typeformTestId
                    completedBy
                    testType
                }
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`

/**
 * __useGetAllTestsQuery__
 *
 * To run a query within a React component, call `useGetAllTestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTestsQuery({
 *   variables: {
 *      searchPhrase: // value for 'searchPhrase'
 *   },
 * });
 */
export function useGetAllTestsQuery(
    baseOptions?: Apollo.QueryHookOptions<GetAllTestsQuery, GetAllTestsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetAllTestsQuery, GetAllTestsQueryVariables>(GetAllTestsDocument, options)
}
export function useGetAllTestsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetAllTestsQuery, GetAllTestsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetAllTestsQuery, GetAllTestsQueryVariables>(GetAllTestsDocument, options)
}
export type GetAllTestsQueryHookResult = ReturnType<typeof useGetAllTestsQuery>
export type GetAllTestsLazyQueryHookResult = ReturnType<typeof useGetAllTestsLazyQuery>
export type GetAllTestsQueryResult = Apollo.QueryResult<GetAllTestsQuery, GetAllTestsQueryVariables>
export const GetSolutionDocument = gql`
    query GetSolution($uuid: UUID!) {
        solution(uuid: $uuid) {
            uuid
            testRoleName
            starred
            solutionState
            submittedAt
            score
            timeSpent
            seenAt
            responses {
                uuid
                index
                outdated
                question {
                    uuid
                    custom
                    questionType
                    content
                    requiresExternalTool
                    appendix
                    availableTime
                    difficulty
                    questionStatus
                    competency {
                        uuid
                        name
                        description
                        domain
                        icon
                    }
                    answers {
                        uuid
                        correct
                        content
                    }
                }
                answersGiven {
                    uuid
                    correct
                    userSelected
                    content
                    answer {
                        uuid
                        correct
                        content
                    }
                }
            }
            test {
                uuid
                availableTime
                roleName
                title
                createdAt
                testType
                solutionsDistribution {
                    label
                    value
                }
            }
            summary {
                competency {
                    uuid
                    name
                }
                numberOfQuestions
                score
            }
        }
    }
`

/**
 * __useGetSolutionQuery__
 *
 * To run a query within a React component, call `useGetSolutionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSolutionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSolutionQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetSolutionQuery(baseOptions: Apollo.QueryHookOptions<GetSolutionQuery, GetSolutionQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetSolutionQuery, GetSolutionQueryVariables>(GetSolutionDocument, options)
}
export function useGetSolutionLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetSolutionQuery, GetSolutionQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetSolutionQuery, GetSolutionQueryVariables>(GetSolutionDocument, options)
}
export type GetSolutionQueryHookResult = ReturnType<typeof useGetSolutionQuery>
export type GetSolutionLazyQueryHookResult = ReturnType<typeof useGetSolutionLazyQuery>
export type GetSolutionQueryResult = Apollo.QueryResult<GetSolutionQuery, GetSolutionQueryVariables>
export const StarSolutionDocument = gql`
    mutation StarSolution($id: UUID!, $starred: Boolean!) {
        recruiterStarSolution(starSolution: { solutionId: $id, starred: $starred }) {
            result
        }
    }
`
export type StarSolutionMutationFn = Apollo.MutationFunction<StarSolutionMutation, StarSolutionMutationVariables>

/**
 * __useStarSolutionMutation__
 *
 * To run a mutation, you first call `useStarSolutionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStarSolutionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [starSolutionMutation, { data, loading, error }] = useStarSolutionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      starred: // value for 'starred'
 *   },
 * });
 */
export function useStarSolutionMutation(
    baseOptions?: Apollo.MutationHookOptions<StarSolutionMutation, StarSolutionMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<StarSolutionMutation, StarSolutionMutationVariables>(StarSolutionDocument, options)
}
export type StarSolutionMutationHookResult = ReturnType<typeof useStarSolutionMutation>
export type StarSolutionMutationResult = Apollo.MutationResult<StarSolutionMutation>
export type StarSolutionMutationOptions = Apollo.BaseMutationOptions<
    StarSolutionMutation,
    StarSolutionMutationVariables
>
export const DeleteTestDocument = gql`
    mutation DeleteTest($id: UUID!) {
        recruiterDeleteTest(testId: $id) {
            result
        }
    }
`
export type DeleteTestMutationFn = Apollo.MutationFunction<DeleteTestMutation, DeleteTestMutationVariables>

/**
 * __useDeleteTestMutation__
 *
 * To run a mutation, you first call `useDeleteTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTestMutation, { data, loading, error }] = useDeleteTestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTestMutation(
    baseOptions?: Apollo.MutationHookOptions<DeleteTestMutation, DeleteTestMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<DeleteTestMutation, DeleteTestMutationVariables>(DeleteTestDocument, options)
}
export type DeleteTestMutationHookResult = ReturnType<typeof useDeleteTestMutation>
export type DeleteTestMutationResult = Apollo.MutationResult<DeleteTestMutation>
export type DeleteTestMutationOptions = Apollo.BaseMutationOptions<DeleteTestMutation, DeleteTestMutationVariables>
export const AuthenticateUserDocument = gql`
    mutation AuthenticateUser($email: String!, $password: String!) {
        auth(email: $email, password: $password) {
            authorizationToken
        }
    }
`
export type AuthenticateUserMutationFn = Apollo.MutationFunction<
    AuthenticateUserMutation,
    AuthenticateUserMutationVariables
>

/**
 * __useAuthenticateUserMutation__
 *
 * To run a mutation, you first call `useAuthenticateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateUserMutation, { data, loading, error }] = useAuthenticateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAuthenticateUserMutation(
    baseOptions?: Apollo.MutationHookOptions<AuthenticateUserMutation, AuthenticateUserMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<AuthenticateUserMutation, AuthenticateUserMutationVariables>(
        AuthenticateUserDocument,
        options
    )
}
export type AuthenticateUserMutationHookResult = ReturnType<typeof useAuthenticateUserMutation>
export type AuthenticateUserMutationResult = Apollo.MutationResult<AuthenticateUserMutation>
export type AuthenticateUserMutationOptions = Apollo.BaseMutationOptions<
    AuthenticateUserMutation,
    AuthenticateUserMutationVariables
>
export const ResetPasswordDocument = gql`
    mutation ResetPassword($email: SendResetPasswordInput!) {
        sendResetPassword(resetPassword: $email) {
            result
        }
    }
`
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options)
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
>
export const SetPasswordDocument = gql`
    mutation SetPassword($password: String!, $resetToken: String!) {
        resetPassword(password: $password, resetToken: $resetToken) {
            authorizationToken
        }
    }
`
export type SetPasswordMutationFn = Apollo.MutationFunction<SetPasswordMutation, SetPasswordMutationVariables>

/**
 * __useSetPasswordMutation__
 *
 * To run a mutation, you first call `useSetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPasswordMutation, { data, loading, error }] = useSetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      resetToken: // value for 'resetToken'
 *   },
 * });
 */
export function useSetPasswordMutation(
    baseOptions?: Apollo.MutationHookOptions<SetPasswordMutation, SetPasswordMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<SetPasswordMutation, SetPasswordMutationVariables>(SetPasswordDocument, options)
}
export type SetPasswordMutationHookResult = ReturnType<typeof useSetPasswordMutation>
export type SetPasswordMutationResult = Apollo.MutationResult<SetPasswordMutation>
export type SetPasswordMutationOptions = Apollo.BaseMutationOptions<SetPasswordMutation, SetPasswordMutationVariables>
export const GetTestDocument = gql`
    query GetTest($id: UUID!) {
        test(uuid: $id) {
            uuid
            testType
            availableTime
            organizationName
            createdAt
            questions {
                uuid
                content
                difficulty
                availableTime
                competency {
                    uuid
                    name
                    description
                    domain
                    icon
                }
            }
            description
            roleName
            seniority
            testType
            testState
            title
            typeformTestId
            competencies {
                uuid
                name
                description
                domain
                icon
            }
            assignedTo {
                uuid
                firstName
                lastName
                organizationName
            }
            averageSummary {
                competency {
                    uuid
                    name
                }
                numberOfQuestions
                score
            }
            solutionsDistribution {
                label
                value
            }
            solutionUuids
            averageSolutionTime
            averageSolutionScore
            completedBy
        }
    }
`

/**
 * __useGetTestQuery__
 *
 * To run a query within a React component, call `useGetTestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTestQuery(baseOptions: Apollo.QueryHookOptions<GetTestQuery, GetTestQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetTestQuery, GetTestQueryVariables>(GetTestDocument, options)
}
export function useGetTestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTestQuery, GetTestQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetTestQuery, GetTestQueryVariables>(GetTestDocument, options)
}
export type GetTestQueryHookResult = ReturnType<typeof useGetTestQuery>
export type GetTestLazyQueryHookResult = ReturnType<typeof useGetTestLazyQuery>
export type GetTestQueryResult = Apollo.QueryResult<GetTestQuery, GetTestQueryVariables>
export const GetTestCandidateDocument = gql`
    query GetTestCandidate($id: UUID!) {
        candidateTestPreview(uuid: $id) {
            ... on TestPreview {
                __typename
                uuid
                title
                roleName
                availableTime
                numberOfQuestions
                description
                organizationName
                requiresExternalTool
                locale
                competencies {
                    uuid
                    name
                    description
                    domain
                    icon
                }
            }
            ... on TestPreviewUnavailable {
                reason
                __typename
            }
        }
    }
`

/**
 * __useGetTestCandidateQuery__
 *
 * To run a query within a React component, call `useGetTestCandidateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTestCandidateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTestCandidateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTestCandidateQuery(
    baseOptions: Apollo.QueryHookOptions<GetTestCandidateQuery, GetTestCandidateQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetTestCandidateQuery, GetTestCandidateQueryVariables>(GetTestCandidateDocument, options)
}
export function useGetTestCandidateLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetTestCandidateQuery, GetTestCandidateQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetTestCandidateQuery, GetTestCandidateQueryVariables>(GetTestCandidateDocument, options)
}
export type GetTestCandidateQueryHookResult = ReturnType<typeof useGetTestCandidateQuery>
export type GetTestCandidateLazyQueryHookResult = ReturnType<typeof useGetTestCandidateLazyQuery>
export type GetTestCandidateQueryResult = Apollo.QueryResult<GetTestCandidateQuery, GetTestCandidateQueryVariables>
export const Issue2FaTokenDocument = gql`
    mutation Issue2FAToken($testId: UUID!, $phoneNumber: String!) {
        candidateIssue2faToken(testId: $testId, phoneNumber: $phoneNumber) {
            result
        }
    }
`
export type Issue2FaTokenMutationFn = Apollo.MutationFunction<Issue2FaTokenMutation, Issue2FaTokenMutationVariables>

/**
 * __useIssue2FaTokenMutation__
 *
 * To run a mutation, you first call `useIssue2FaTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssue2FaTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issue2FaTokenMutation, { data, loading, error }] = useIssue2FaTokenMutation({
 *   variables: {
 *      testId: // value for 'testId'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useIssue2FaTokenMutation(
    baseOptions?: Apollo.MutationHookOptions<Issue2FaTokenMutation, Issue2FaTokenMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Issue2FaTokenMutation, Issue2FaTokenMutationVariables>(Issue2FaTokenDocument, options)
}
export type Issue2FaTokenMutationHookResult = ReturnType<typeof useIssue2FaTokenMutation>
export type Issue2FaTokenMutationResult = Apollo.MutationResult<Issue2FaTokenMutation>
export type Issue2FaTokenMutationOptions = Apollo.BaseMutationOptions<
    Issue2FaTokenMutation,
    Issue2FaTokenMutationVariables
>
export const Verify2faTokenDocument = gql`
    mutation Verify2faToken($testId: UUID!, $phoneNumber: String!, $verificationToken: String!) {
        candidateVerify2faToken(testId: $testId, phoneNumber: $phoneNumber, verificationToken: $verificationToken) {
            intermediateToken
        }
    }
`
export type Verify2faTokenMutationFn = Apollo.MutationFunction<Verify2faTokenMutation, Verify2faTokenMutationVariables>

/**
 * __useVerify2faTokenMutation__
 *
 * To run a mutation, you first call `useVerify2faTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerify2faTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verify2faTokenMutation, { data, loading, error }] = useVerify2faTokenMutation({
 *   variables: {
 *      testId: // value for 'testId'
 *      phoneNumber: // value for 'phoneNumber'
 *      verificationToken: // value for 'verificationToken'
 *   },
 * });
 */
export function useVerify2faTokenMutation(
    baseOptions?: Apollo.MutationHookOptions<Verify2faTokenMutation, Verify2faTokenMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<Verify2faTokenMutation, Verify2faTokenMutationVariables>(Verify2faTokenDocument, options)
}
export type Verify2faTokenMutationHookResult = ReturnType<typeof useVerify2faTokenMutation>
export type Verify2faTokenMutationResult = Apollo.MutationResult<Verify2faTokenMutation>
export type Verify2faTokenMutationOptions = Apollo.BaseMutationOptions<
    Verify2faTokenMutation,
    Verify2faTokenMutationVariables
>
export const RegisterCandidateDocument = gql`
    mutation RegisterCandidate($email: String!, $firstName: String!, $intermediateToken: String!, $lastName: String!) {
        registerCandidate(
            email: $email
            firstName: $firstName
            intermediateToken: $intermediateToken
            lastName: $lastName
        ) {
            candidateSessionToken
        }
    }
`
export type RegisterCandidateMutationFn = Apollo.MutationFunction<
    RegisterCandidateMutation,
    RegisterCandidateMutationVariables
>

/**
 * __useRegisterCandidateMutation__
 *
 * To run a mutation, you first call `useRegisterCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCandidateMutation, { data, loading, error }] = useRegisterCandidateMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      intermediateToken: // value for 'intermediateToken'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useRegisterCandidateMutation(
    baseOptions?: Apollo.MutationHookOptions<RegisterCandidateMutation, RegisterCandidateMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<RegisterCandidateMutation, RegisterCandidateMutationVariables>(
        RegisterCandidateDocument,
        options
    )
}
export type RegisterCandidateMutationHookResult = ReturnType<typeof useRegisterCandidateMutation>
export type RegisterCandidateMutationResult = Apollo.MutationResult<RegisterCandidateMutation>
export type RegisterCandidateMutationOptions = Apollo.BaseMutationOptions<
    RegisterCandidateMutation,
    RegisterCandidateMutationVariables
>
export const RecruiterSignUpDocument = gql`
    mutation RecruiterSignUp($signUp: SignUpInput!) {
        recruiterSignUp(signUp: $signUp) {
            payload {
                status
                user {
                    email
                    uuid
                    accountId
                }
                errors {
                    name
                    messages
                }
            }
        }
    }
`
export type RecruiterSignUpMutationFn = Apollo.MutationFunction<
    RecruiterSignUpMutation,
    RecruiterSignUpMutationVariables
>

/**
 * __useRecruiterSignUpMutation__
 *
 * To run a mutation, you first call `useRecruiterSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecruiterSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recruiterSignUpMutation, { data, loading, error }] = useRecruiterSignUpMutation({
 *   variables: {
 *      signUp: // value for 'signUp'
 *   },
 * });
 */
export function useRecruiterSignUpMutation(
    baseOptions?: Apollo.MutationHookOptions<RecruiterSignUpMutation, RecruiterSignUpMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<RecruiterSignUpMutation, RecruiterSignUpMutationVariables>(
        RecruiterSignUpDocument,
        options
    )
}
export type RecruiterSignUpMutationHookResult = ReturnType<typeof useRecruiterSignUpMutation>
export type RecruiterSignUpMutationResult = Apollo.MutationResult<RecruiterSignUpMutation>
export type RecruiterSignUpMutationOptions = Apollo.BaseMutationOptions<
    RecruiterSignUpMutation,
    RecruiterSignUpMutationVariables
>
export const CandidateAttemptSolutionDocument = gql`
    mutation CandidateAttemptSolution($candidateSessionToken: String!) {
        candidateAttemptSolution(candidateSessionToken: $candidateSessionToken) {
            typeformTestId
        }
    }
`
export type CandidateAttemptSolutionMutationFn = Apollo.MutationFunction<
    CandidateAttemptSolutionMutation,
    CandidateAttemptSolutionMutationVariables
>

/**
 * __useCandidateAttemptSolutionMutation__
 *
 * To run a mutation, you first call `useCandidateAttemptSolutionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCandidateAttemptSolutionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [candidateAttemptSolutionMutation, { data, loading, error }] = useCandidateAttemptSolutionMutation({
 *   variables: {
 *      candidateSessionToken: // value for 'candidateSessionToken'
 *   },
 * });
 */
export function useCandidateAttemptSolutionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CandidateAttemptSolutionMutation,
        CandidateAttemptSolutionMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<CandidateAttemptSolutionMutation, CandidateAttemptSolutionMutationVariables>(
        CandidateAttemptSolutionDocument,
        options
    )
}
export type CandidateAttemptSolutionMutationHookResult = ReturnType<typeof useCandidateAttemptSolutionMutation>
export type CandidateAttemptSolutionMutationResult = Apollo.MutationResult<CandidateAttemptSolutionMutation>
export type CandidateAttemptSolutionMutationOptions = Apollo.BaseMutationOptions<
    CandidateAttemptSolutionMutation,
    CandidateAttemptSolutionMutationVariables
>
export const GetCandidateSolutionPreviewDocument = gql`
    query GetCandidateSolutionPreview($sessionToken: String!) {
        candidateSolutionPreview(sessionToken: $sessionToken) {
            test {
                uuid
                organizationName
                roleName
            }
            solutionState
            candidate {
                uuid
                email
                firstName
            }
            score
            timeSpent
            summary {
                competency {
                    uuid
                    name
                }
                score
            }
        }
    }
`

/**
 * __useGetCandidateSolutionPreviewQuery__
 *
 * To run a query within a React component, call `useGetCandidateSolutionPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCandidateSolutionPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCandidateSolutionPreviewQuery({
 *   variables: {
 *      sessionToken: // value for 'sessionToken'
 *   },
 * });
 */
export function useGetCandidateSolutionPreviewQuery(
    baseOptions: Apollo.QueryHookOptions<GetCandidateSolutionPreviewQuery, GetCandidateSolutionPreviewQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useQuery<GetCandidateSolutionPreviewQuery, GetCandidateSolutionPreviewQueryVariables>(
        GetCandidateSolutionPreviewDocument,
        options
    )
}
export function useGetCandidateSolutionPreviewLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetCandidateSolutionPreviewQuery,
        GetCandidateSolutionPreviewQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useLazyQuery<GetCandidateSolutionPreviewQuery, GetCandidateSolutionPreviewQueryVariables>(
        GetCandidateSolutionPreviewDocument,
        options
    )
}
export type GetCandidateSolutionPreviewQueryHookResult = ReturnType<typeof useGetCandidateSolutionPreviewQuery>
export type GetCandidateSolutionPreviewLazyQueryHookResult = ReturnType<typeof useGetCandidateSolutionPreviewLazyQuery>
export type GetCandidateSolutionPreviewQueryResult = Apollo.QueryResult<
    GetCandidateSolutionPreviewQuery,
    GetCandidateSolutionPreviewQueryVariables
>
export const CandidateGiveFeedbackDocument = gql`
    mutation CandidateGiveFeedback($candidateSessionToken: String!, $feedbackScore: Decimal, $feedbackText: String) {
        candidateGiveFeedback(
            candidateSessionToken: $candidateSessionToken
            feedbackScore: $feedbackScore
            feedbackText: $feedbackText
        ) {
            result
        }
    }
`
export type CandidateGiveFeedbackMutationFn = Apollo.MutationFunction<
    CandidateGiveFeedbackMutation,
    CandidateGiveFeedbackMutationVariables
>

/**
 * __useCandidateGiveFeedbackMutation__
 *
 * To run a mutation, you first call `useCandidateGiveFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCandidateGiveFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [candidateGiveFeedbackMutation, { data, loading, error }] = useCandidateGiveFeedbackMutation({
 *   variables: {
 *      candidateSessionToken: // value for 'candidateSessionToken'
 *      feedbackScore: // value for 'feedbackScore'
 *      feedbackText: // value for 'feedbackText'
 *   },
 * });
 */
export function useCandidateGiveFeedbackMutation(
    baseOptions?: Apollo.MutationHookOptions<CandidateGiveFeedbackMutation, CandidateGiveFeedbackMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions }
    return Apollo.useMutation<CandidateGiveFeedbackMutation, CandidateGiveFeedbackMutationVariables>(
        CandidateGiveFeedbackDocument,
        options
    )
}
export type CandidateGiveFeedbackMutationHookResult = ReturnType<typeof useCandidateGiveFeedbackMutation>
export type CandidateGiveFeedbackMutationResult = Apollo.MutationResult<CandidateGiveFeedbackMutation>
export type CandidateGiveFeedbackMutationOptions = Apollo.BaseMutationOptions<
    CandidateGiveFeedbackMutation,
    CandidateGiveFeedbackMutationVariables
>
